import debounce from "@mui/material/utils/debounce";

export function asyncDebounce<T extends Function>(func: T, wait: number): T {
  const debounced = debounce(async (resolve, reject, bindSelf, args) => {
    try {
      const result = await func.bind(bindSelf)(...args);
      resolve(result);
    } catch (error) {
      reject(error);
    }
  }, wait);

  function returnFunc(this: any, ...args: any) {
    return new Promise((resolve, reject) => {
      debounced(resolve, reject, this, args);
    });
  }
  return returnFunc as unknown as T;
}
