import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from 'redux';
import { actionCreators } from "../../state/index";
import API from "../../Services/API";
import { Button } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import './SelectZone.css';
import SpinnerView from "../../Components/SpinnerView/SpinnerView";
import AlertView from "../../Components/AlertView/AlertView";
import AlertSingleButtonModel from "../../Model/AlertSingleButtonModel";
import AlertDualButtonModel from "../../Model/AlertDualButtonModel";
import { useIdleTimer } from 'react-idle-timer';
import CloseIcon from "../../Images/close-no-border.svg";

function SelectZone() {
  var dataAlertSingleButtonModel: AlertSingleButtonModel = {};
  var dataAlertDualButtonModel: AlertDualButtonModel = {};
  const navigate = useNavigate();
  const state = useSelector((state: any) => state);
  const SessionDetails = state.user.SessionDetails;
  const MultiSessionDetails = state.user.MultiSessionDetails;
  const [spinner, setSpinner] = useState(false);
  const dispatch = useDispatch();
  const { addMultiSession, addSession } = bindActionCreators(actionCreators, dispatch);
  const api = new API();
  const [Zones, setZones] = useState([]);
  const [selectedZoneGuid, setSelectedZoneGuid] = useState([]);
  const [alertSingleData, setAlertSingleData] = useState(dataAlertSingleButtonModel);
  const [alertDualData, setAlertDualData] = useState(dataAlertDualButtonModel);
  const isRunned = useRef(false);
  const timeout = 1000 * 60 * 15;
  const [isIdle, setIsIdle] = useState(false);
  const handleOnActive = () => setIsIdle(false);
  const handleOnIdle = () => setIsIdle(true);

  useIdleTimer({
    timeout,
    onActive: handleOnActive,
    onIdle: handleOnIdle
  });

  useEffect(() => {
    const set = setInterval(() => {
      if (isIdle) {
        setTimeout(() => {
          clearInterval(set)
          setAlertSingleData({
            showAlert: true,
            alertData: {
              startImg: "",
              title: "Session-Out",
              subTitle: "Your session has expired due to 15 minutes of inactivity.",
              okayButtonName: "Ok",
              actionName: "Session-Out"
            }
          });
        }, 1000);
      }
    }, 1000);
    return () => {
      clearInterval(set);
    }
  }, [isIdle]);

  useEffect(() => {
    if (!localStorage.getItem('access_token') || (!SessionDetails.propertyGuid)) {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    !isRunned.current &&
      getZone();
    return () => {
      isRunned.current = true;
    };
  }, []);

  useEffect(() => {
    window.onbeforeunload = function () {
      return true;
    };
    return () => {
      window.onbeforeunload = null;
    };
  });

  useEffect(() => {
    window.history.pushState(null, "", window.location.pathname);
    window.addEventListener('close', onBackButtonEvent);
    return () => {
      window.removeEventListener('close', onBackButtonEvent);
    };
  }, []);

  const [finishStatus, setfinishStatus] = useState(false);

  const onBackButtonEvent = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    if (!finishStatus) {
      setAlertDualData({
        showAlert: true,
        alertData: {
          title: "Enforcement Check Complete",
          subTitle: `Parking enforcement check for ${SessionDetails.propertyName} - ${MultiSessionDetails.length > 0 ? MultiSessionDetails.map((x: any) => x["selectedZone"]).join(",") : ""} has been successfully completed. To proceed, please choose one of the following options:`,
          startImg: "",
          dualAButton: "End Session",
          dualBButton: "New Session",
          actionName: "End-Session",
          closeButton: CloseIcon
        }
      });
    }
  };

  useEffect(() => {
    window.history.pushState(null, "", window.location.pathname);
    window.addEventListener('popstate', onBackButtonEvent);
    return () => {
      window.removeEventListener('popstate', onBackButtonEvent);
    };
  }, []);

  const handleAlertOkayButton = (actionName: string) => {
    if (actionName === "ERROR-API") {
      setAlertSingleData({ showAlert: false })
    } else if (actionName === "Session-Out") {
      setAlertSingleData({ showAlert: false });
      getEndSession();
    }
  };

  const handleDualAButton = (actionName: string) => {
    setAlertDualData({ showAlert: false });
    if (actionName === "End-Session") {
      setfinishStatus(true);
      localStorage.removeItem('access_token');
      navigate('/');
    }
  };

  const handleDualBButton = (actionName: string) => {
    setAlertDualData({ showAlert: false });
    if(actionName === "End-Session"){
      setfinishStatus(true);
      getEndSession();
    }
  };

  const ShowokayAlert = () => {
    var startImg = (alertSingleData.alertData?.startImg) ? alertSingleData.alertData?.startImg : "";
    var Title = (alertSingleData.alertData?.title) ? alertSingleData.alertData?.title : "";
    var SubTitle = (alertSingleData.alertData?.subTitle) ? alertSingleData.alertData?.subTitle : "";
    var OkayButtonName = (alertSingleData.alertData?.okayButtonName) ? alertSingleData.alertData?.okayButtonName : "";
    var ActionName = (alertSingleData.alertData?.actionName) ? alertSingleData.alertData?.actionName : "";
    return (
      alertSingleData.showAlert && (
        <AlertView
          startImg={startImg}
          title={Title}
          subTitle={SubTitle}
          singleOkButton={OkayButtonName}
          handleOkay={() => handleAlertOkayButton(ActionName)}
        />
      )
    );
  };

  const handleClose = () => {
    setAlertDualData({
      showAlert: false,
    })
  };

  const ShowDualAlert = () => {
    var Title = (alertDualData.alertData?.title) ? alertDualData.alertData?.title : "";
    var SubTitle = (alertDualData.alertData?.subTitle) ? alertDualData.alertData?.subTitle : "";
    var DualAButton = (alertDualData.alertData?.dualAButton) ? alertDualData.alertData?.dualAButton : "";
    var DualBButton = (alertDualData.alertData?.dualBButton) ? alertDualData.alertData?.dualBButton : "";
    var startImg = (alertDualData.alertData?.startImg) ? alertDualData.alertData?.startImg : "";
    var ActionName = (alertDualData.alertData?.actionName) ? alertDualData.alertData?.actionName : "";
    var closeButton = alertDualData.alertData?.closeButton;
    return (
      alertDualData.showAlert && (
        <AlertView
            title={Title}
            subTitle={SubTitle}
            startImg={startImg}
            closeButton={closeButton}
            dualAButton={DualAButton}
            dualBButton={DualBButton}
            handleDualAButton={() => handleDualAButton(ActionName)}
            handleDualBButton={() => handleDualBButton(ActionName)}
            handleClose={handleClose}
        />
      )
    );
  };

  const showApiError = (message: string) => {
    setAlertSingleData({
      showAlert: true, alertData: {
        startImg: "",
        title: "GrydPark",
        subTitle: message,
        okayButtonName: "Ok",
        actionName: "ERROR-API"
      }
    });
  };

  const getEndSession = async () => {
    if (String(localStorage.getItem('end_session_guid'))) {
      setSpinner(true);
      await api.get_end_session(String(localStorage.getItem('end_session_guid')));
      // if (endSessionRes === 200) {
        localStorage.removeItem('end_session_guid');
        navigate("/FindSpot");
      // }
      setSpinner(false);
    } else {
      navigate("/FindSpot");
    }
  };

  const getZone = async () => {
    if (!navigator.onLine) {
      showApiError("You need an internet connection to perform this action.");
      return;
    }
    setSpinner(true);
    var endSessionGuid = "";
    const PostData = {
      propertyGuid: SessionDetails.propertyGuid,
    };
    const { result: propertyZone_, offline, error } = await api.get_zone(PostData);
    const { result: reasonList, offline: offline2, error: error2 } = await api.get_reason_list();
    setSpinner(false);
    if (offline || offline2) {
      showApiError("Connection error: make sure you're connected to the Internet.");
      return;
    }
    if (Array.isArray(propertyZone_) && Array.isArray(reasonList) && !error && !error2) {
      for (var i = 0; i < propertyZone_.length; i++) {
        var value = propertyZone_[i]
        endSessionGuid = value["sessionStartedGuid"];
      }
      localStorage.setItem('end_session_guid', endSessionGuid);
      localStorage.setItem('current_session', JSON.stringify(propertyZone_));
      localStorage.setItem('reasons', JSON.stringify(reasonList));
      localStorage.removeItem('parkers');
      localStorage.removeItem('scramble_parkers');
      setZones(propertyZone_);
    } else {
      showApiError("Something went wrong. Try reloading the app.");
    }
  }

  const sendLotItems = (lotItems: never[]) => {
    const listItems: JSX.Element[] = [];
    lotItems.map((ze: never, index) => {
      const isSelectedZone = selectedZoneGuid.findIndex(selectZone => selectZone === ze["zoneGuid"]);
      const checkStyle = {
        backgroundColor: isSelectedZone > -1 ? '#00AF85' : 'white',
        borderColor: isSelectedZone > -1 ? '#00AF85' : '#8A959E',
      };
      listItems.push(<div key={`zone-${index}_${ze["zoneGuid"]}`}>
        <div style={{ 'margin': '10px' }} className="RowView" onClick={() => {
          let newSelectedZoneGuid = JSON.parse(JSON.stringify(selectedZoneGuid));
          const selectedZones = Zones.filter(zone => newSelectedZoneGuid.indexOf(zone["zoneGuid"]) !== -1)
            .map(zone => zone["isScrambleLot"]);

          const scrambleLotType = selectedZones.length > 0 ? selectedZones[0] : null;

          if (scrambleLotType !== null) {
            if (scrambleLotType === ze["isScrambleLot"]) {
              const isZoneGuidExist = newSelectedZoneGuid.findIndex((x: any) => x === ze["zoneGuid"]) !== -1;
              if (isZoneGuidExist) {
                newSelectedZoneGuid = newSelectedZoneGuid.filter((x: any) => x !== ze["zoneGuid"]);
              } else {
                newSelectedZoneGuid.push(ze["zoneGuid"]);
              }
            } else {
              newSelectedZoneGuid = [];
              newSelectedZoneGuid.push(ze["zoneGuid"]);
            }
          } else {
            newSelectedZoneGuid.push(ze["zoneGuid"]);
          }

          setSelectedZoneGuid(newSelectedZoneGuid);

          var zone = Zones.filter(zone => newSelectedZoneGuid.indexOf(zone["zoneGuid"]) !== -1);

          const SessionDetailstemp = {
            curLatitude: SessionDetails.curLatitude,
            curLongitude: SessionDetails.curLongitude,
            propertyGuid: SessionDetails.propertyGuid,
            propertyName: SessionDetails.propertyName,
            tempReportedArr: SessionDetails.tempReportedArr
          };
          const sessionDetails = zone.map(z => {
            return {
              curLatitude: SessionDetails.curLatitude,
              curLongitude: SessionDetails.curLongitude,
              propertyGuid: SessionDetails.propertyGuid,
              propertyName: SessionDetails.propertyName,
              selectedZone: z["zoneName"],
              selectedZoneGuid: z["zoneGuid"],
              tempReportedArr: MultiSessionDetails.length > 0 ? MultiSessionDetails[0].tempReportedArr : [] as string[]
            };
          });
          addMultiSession(sessionDetails);
          addSession(SessionDetailstemp);
        }}>
          <div style={checkStyle} className="SelectBox"></div>
          <small style={{ 'margin': '5px', }} className="slogan-title">{ze["zoneName"]}</small>
        </div>
      </div>
      );
      return ze;
    });
    return listItems;
  };

  const createListItems = (Zones: never[]) => {
    const scrambleLotItems = Zones.filter((zone: any) => zone.isScrambleLot).sort((a: any, b: any) =>
      b.zoneName.localeCompare(a.zoneName, undefined, { numeric: true }));
    const nonScrambleLotItems = Zones.filter((zone: any) => !zone.isScrambleLot).sort((a: any, b: any) =>
      b.zoneName.localeCompare(a.zoneName, undefined, { numeric: true }));

    let listItems = [];
    if (nonScrambleLotItems.length > 0) {
      listItems.push(...sendLotItems(nonScrambleLotItems));
    }

    if (scrambleLotItems.length > 0) {
      nonScrambleLotItems.length > 0 && listItems.push(<div style={{ margin: '10px' }} className="itemDiv"></div>);
      listItems.push(...sendLotItems(scrambleLotItems));
    }
    return listItems;
  };

  const listItems = Zones?.length > 0 ? createListItems(Zones) : [];

  return (
    <div className="adjust-screen" style={{
      display: 'grid',
      height: '100vh',
      placeContent: 'start',
      placeItems: 'center',
    }}>
      {spinner && <SpinnerView />}
      {ShowokayAlert()}
      {ShowDualAlert()}
      <div style={{
        overflowY: 'auto',
        overflow: 'auto',
        height: 'calc(79vh)',
        display: 'flex',
        justifyContent: 'center',
      }}>
        <div className="BaseContainer">
          <div className='Sub-Container-View'>
            <div style={{ 'marginTop': '20px' }} className='RightView'>
              <Button
                onClick={() => {
                  setAlertDualData({
                    showAlert: true,
                    alertData: {
                      title: "Enforcement Check Complete",
                      subTitle: `Parking enforcement check for ${SessionDetails.propertyName} - ${MultiSessionDetails.length > 0 ? MultiSessionDetails.map((x: any) => x["selectedZone"]).join(",") : ""} has been successfully completed. To proceed, please choose one of the following options:`,
                      startImg: "",
                      dualAButton: "End Session",
                      dualBButton: "New Session",
                      actionName: "End-Session",
                      closeButton: CloseIcon
                    }
                  });
                }}
              >End Session</Button>
            </div>
            <div style={{ marginTop: '20px' }} className='min-hight'>
              <div className="CenterView">
                <h2 className="title">{SessionDetails.propertyName}</h2>
              </div>
              <div className="BoxView">
                <div style={{ 'margin': '5px' }}>
                  <div className="CenterView">
                    <h2 style={{ 'marginTop': '10px' }} className="title">Select A Zone</h2>
                    <div className='slogan-container'>
                      <h1 className='slogan-title'>Select your zone and hit start. Please review the license plates and confirm they are in the correct spots.
                      </h1>
                    </div>
                  </div>
                  <div style={{}}>{listItems}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='BaseContainer' style={{ 'marginTop': '10px' }}>
        <div style={{ width: '85%' }}>
          <Button
            style={{
              width: '100%',
              height: '48px',
              backgroundColor: selectedZoneGuid.length > 0 ? '#00AF85' : '#8A959E',
              fontSize: "15px",
              fontFamily: 'Manrope',
              fontWeight: '700',
              borderRadius: '12px'
            }}
            variant="contained"
            onClick={() => {
              if (!navigator.onLine) {
                showApiError("You need an internet connection to perform this action.");
                return;
              }
              if (selectedZoneGuid.length > 0) {
                var zone = Zones.filter(zone => selectedZoneGuid.indexOf(zone["zoneGuid"]) !== -1 && zone["isScrambleLot"]);
                if (zone.length > 0) {
                  navigate('/ScrambleLot', { state: {} });
                } else {
                  navigate('/ListOfParkers', { state: {
                    propertyGuid: SessionDetails.propertyGuid,
                    ZoneGuid: [selectedZoneGuid]
                  } });
                }
              }
            }}>Start</Button>
        </div>
      </div>
    </div>
  );
}

export default SelectZone;
