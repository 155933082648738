import React from 'react';
import './App.css';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Routes, Route } from "react-router-dom";
import LoginScreen from "./Screens/Login/Login";
import SpotSearch from './Screens/SpotSearch/SpotSearch';
import SelectZone from './Screens/SelectZone/SelectZone';
import ListOfParkers from './Screens/ListOfParkers/ListOfParkers';
import ScrambleLot from './Screens/ScrambleLot/ScrambleLot';
import ParkerDetail from './Screens/ParkerDetail/ParkerDetail';
import NetworkStatus from './Components/NetworkStatus/NetworkStatus';

// import { createTheme, ThemeProvider } from '@mui/material/styles';

function App() {
  const theme = createTheme({

    shape: {
      borderRadius: 8,
    },
    spacing: 100,
    palette: {
      primary: {
        light: '#6B7278',
        main: '#00AF85',
        dark: '#6B7278',
        contrastText: '#fff',
      },
      secondary: {
        light: '#6B7278',
        main: '#6B7278',
        dark: '#6B7278',
        contrastText: '#000',
      },
    },

    // components: {

    //   MuiButton: {
    //     styleOverrides: {
    //       root: {
    //         borderRadius: 28,
    //       },
    //     }, 
    //   },
    //   MuiSelect: {
    //     defaultProps: {

    //     }
    //   },

    // },


    typography: {
      fontFamily: ['Manrope'].join(','),
      button: {
        textTransform: 'none'

      },

    },

  });

  return (
    <ThemeProvider theme={theme}>
      <div className='Root-Container'>
        <div className='Max-Width'>
          <NetworkStatus />
          <Routes>
            <Route path="/" element={<LoginScreen />} />
            <Route path="/FindSpot" element={<SpotSearch />} />
            <Route path="/SelectZone" element = {<SelectZone/>} />
            <Route path="/ListOfParkers" element = {<ListOfParkers/>} />
            <Route path='/ParkerDetail' element = {<ParkerDetail/>} />
            <Route path="/ScrambleLot" element = {<ScrambleLot/>} />
          </Routes>
        </div>
      </div>
    </ThemeProvider>
  );
}

export default App;
