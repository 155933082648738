import { Button } from "@mui/material";
import React, {useState, useEffect} from "react";
import './AlertView.css'

const AlertView = (_props: any) => {
    // console.log("_props",_props.buttonType);
    const [Title, setTitle] = useState("");
    const [SubTitle, setSubTitle] = useState("");
    const [, setBottomTitle] = useState("")
    const [singleOkButton, setSingleOkButton] = useState("");
    const [dualAButton, setDualAButton] = useState("");
    const [dualBButton, setDualBButton] = useState("");
    const [startImg, setStartImg] = useState("");
    const closeButton = _props.closeButton;

    useEffect(() => {
        setSingleOkButton(_props.singleOkButton ? _props.singleOkButton : "");
        setDualAButton(_props.dualAButton ? _props.dualAButton : "");
        setDualBButton(_props.dualBButton ? _props.dualBButton : "");
        setTitle(_props.title);
        setSubTitle(_props.subTitle);
        setBottomTitle(_props.BottomTitle ? _props.BottomTitle : "");
        setStartImg(_props.startImg ? _props.startImg : "");
    })



    return (
        <div className="Alert_Screen">
            <div className="popup">
                {closeButton && (
                    <img src={closeButton} style={{ marginTop: '10px', alignSelf: "end" }} alt="" className="close-icon"
                         onClick={() => {
                             _props.handleClose();
                         }}
                    />
                )}
                {startImg !== "" && (
                        <img src={startImg} style={{ marginTop: '30px' }} alt="" className="start-icon"/>
                )}
                <h1 className="Title">
                    {Title}
                </h1>
                <div className="AlertSubTitle" style={{
                    flex: 1,
                    paddingBottom: '24px'
                }}>
                    <p>{SubTitle}</p>
                </div>

                {
                    (dualAButton !== '' && dualBButton !== '') && (
                        <div className="DualButton" style={{padding: '5px'}}>
                            <div style={{ width: '40%' }}>
                                <Button style={{ width: '100%', height: 'auto', minHeight: '40px', backgroundColor: '#FFFFFF', fontSize: "15px", fontFamily: 'Manrope', fontWeight: '700', borderRadius: '12px', color: '#00AF85' }}
                                    variant="outlined"
                                    onClick={() => {
                                        _props.handleDualAButton()
                                    }}>

                                    {dualAButton}
                                </Button>
                            </div>
                            <div style={{ width: '40%' }}>
                                <Button style={{ width: '100%', height: 'auto', minHeight: '40px', backgroundColor: '#00AF85', fontSize: "15px", fontFamily: 'Manrope', fontWeight: '700', borderRadius: '12px', color: 'white' }}
                                    variant="outlined"
                                    onClick={() => {
                                        //  console.log("clicked inside");
                                        _props.handleDualBButton()
                                    }}>
                                    {dualBButton}
                                </Button>
                            </div>
                        </div>
                    )
                }
                {
                    singleOkButton !== "" && (
                        <div className="SingleButton">
                            <Button style={{ width: '100%', height: '40px', backgroundColor: '#00AF85', fontSize: "15px", fontFamily: 'Manrope', fontWeight: '700', borderRadius: '12px' }} variant="contained"
                                onClick={() => {
                                    _props.handleOkay()
                                }}>
                                {singleOkButton}
                            </Button>
                        </div>
                    )
                }



                {/* <div style={{ marginBottom: '20px' }}>
                    {BottomTitle != "" && (
                        <IconButton onClick={
                            () => {
                                window.open("https://help.parkwithgryd.com/article/211-why-cant-i-make-a-booking")
                            }}

                        >
                            <text className="BottomTitle">
                                {BottomTitle}
                            </text>
                        </IconButton>

                    )
                    }
                </div> */}

            </div>
        </div>
    )

}
export default AlertView
