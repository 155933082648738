import React, { useEffect, useState, useRef } from "react";
import './PopUpView.css'
import { Button } from "@mui/material";
import CloseIcon from '../../Images/close-no-border.svg'


function PopUpView(_props: any) {
    const ref = useRef<HTMLInputElement>(null);
    const [title, setTitle] = useState("");
    const [startImg, setStartImg] = useState("");
    const [subTitle, setSubTitle] = useState("");
    const [, setTitle2] = useState("");
    const [subTitle3, setSubTitle3] = useState("");
    const [subTitle2, setSubTitle2] = useState("");
    const [singleOkButton, setSingleOkButton] = useState("");
    const [dualAButton, setDualAButton] = useState("");
    const [dualBButton, setDualBButton] = useState("");
    const [height, setHeight] = useState(0);

    useEffect(() => {
        if (ref.current != null) {
            setHeight(ref.current.clientHeight)
        }
        setTitle(_props.title);
        setSubTitle(_props.subTitle ? _props.subTitle : "");
        setSingleOkButton(_props.singleOkButton ? _props.singleOkButton : "");
        setDualAButton(_props.dualAButton ? _props.dualAButton : "");
        setDualBButton(_props.dualBButton ? _props.dualBButton : "");
        setTitle2(_props.title2 ? _props.title2 : "");
        setSubTitle2(_props.subTitle2 ? _props.subTitle2 : "");
        setStartImg(_props.startImg ? _props.startImg : "");
        setSubTitle3(_props.subTitle3 ? _props.subTitle3 : "");
    })

    return (

        <div className="popUpBasicContrainer">
            <div style={{ 'minHeight': `calc(100vh - ${height}px)` }} className="popUp-min-hight"></div>
            <div style={{}} ref={ref} className="pop-up">
                <div style={{ marginTop: '20px' }} className="RightView">
                    <div >
                        <img src={CloseIcon} className="close-icon" alt=""
                            onClick={() => {
                                _props.handelClose()
                            }}
                        />
                    </div>
                </div>

                <div style={{}} className="CenterView">
                    {
                        startImg !== "" && (
                            <img src={startImg} className="start-icon" alt=""
                                onClick={() => {

                                }}
                            />
                        )
                    }

                    <small className="pop-up-title">{title}</small>
                    <div style={{ width: '80%', 'textAlign': 'center', 'marginTop': '20px' }} className="CenterView">
                        <small className="pop-up-sub-title">{subTitle}</small>
                    </div>
                    {
                        (subTitle2 !== "") && (
                            <div className="CenterView">
                                <div className="short-middle-line"></div>
                                <div style={{ width: '80%', 'textAlign': 'center', 'marginTop': '10px' }} className="CenterView">
                                    <small className="pop-up-sub-title">{subTitle2}</small>
                                </div>
                            </div>
                        )
                    }
                    {
                        (subTitle3 !== "")&&(
                            <div className="CenterView">
                            <div style={{ width: '80%', 'textAlign': 'center', 'marginTop': '10px' }} className="CenterView">
                                <small className="pop-up-sub-title">{subTitle3}</small>
                            </div>
                        </div>
                        )

                    }


                    {(singleOkButton !== "") &&
                        (<div style={{ 'marginTop': "20px", 'width': '90%' }}>
                            <Button style={{ width: '100%', height: '58px', backgroundColor: '#00AF85', fontSize: "15px", fontFamily: 'Manrope', fontWeight: '700', borderRadius: '12px', marginBottom: '20px' }} variant="contained"
                                onClick={() => {
                                    _props.handelSingleOkButton()
                                }
                                }
                            >
                                {singleOkButton}
                            </Button>
                        </div>)}
                    {(dualAButton !== "" || dualBButton !== "") &&
                        (<div style={{ 'marginTop': "20px", 'width': '90%', 'justifyContent': 'space-around' }} className="RowView">

                            {dualAButton !== "" && (
                                <div style={{width: dualBButton === "" ? '80%' : '45%'}}>
                                    <Button style={{
                                        'width': '100%',
                                        'height': '58px',
                                        'backgroundColor': '#FFFFFF',
                                        'fontSize': "15px",
                                        'fontFamily': 'Manrope',
                                        'fontWeight': '700',
                                        'borderRadius': '12px'
                                    }} variant="outlined"
                                            onClick={() => {
                                                _props.handelDualAButton()
                                            }}
                                    >
                                        {dualAButton}
                                    </Button>
                                </div>
                            )}

                            {(dualBButton !== "" &&
                                <div style={{width: dualAButton === "" ? '80%' : '45%'}}>
                                    <Button style={{
                                                width: '100%',
                                                height: '58px',
                                                backgroundColor: '#00AF85',
                                                fontSize: "15px",
                                                fontFamily: 'Manrope',
                                                fontWeight: '700',
                                                borderRadius: '12px',
                                                marginBottom: '20px'
                                            }}
                                            variant="contained"
                                            onClick={() => {_props.handelDualBButton()}}
                                >
                                        {dualBButton}
                                    </Button>
                                </div>
                            )}

                        </div>)
                    }

                </div>
                <div style={{'height':'80px'}}></div>
            </div>
        </div>
    )
}
export default PopUpView
