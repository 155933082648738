const SessionDetails = {
    propertyName: "",
    propertyGuid: "",
    curLatitude: "",
    curLongitude: "",
    selectedZone: "",
    selectedZoneGuid:"",
    sessionGuid:"",
    tempReportedArr : [] as string[]
}

const User = {
    SessionDetails: SessionDetails,
    MultiSessionDetails: []
}

const reducer = (
    state = User,
    action: any
) => {        
    var data = action.payload;
    switch (action.type) {
        case "addSessionDetails":
            SessionDetails["propertyName"] = data.propertyName;
            SessionDetails["propertyGuid"] = data.propertyGuid;
            SessionDetails["curLatitude"] = data.curLatitude;
            SessionDetails["curLongitude"] = data.curLongitude;
            SessionDetails["selectedZone"] = data.selectedZone;
            SessionDetails["selectedZoneGuid"] = data.selectedZoneGuid;
            SessionDetails["sessionGuid"] = data.sessionGuid;
            SessionDetails["tempReportedArr"] = data.tempReportedArr;
            User["SessionDetails"] = SessionDetails
            return User;
        case "addMultiSessionDetails":
            User["MultiSessionDetails"] = data;
            return User;
        default:
            return state;
    }
}

export default reducer;