import React, { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux"
import { useDispatch } from "react-redux";
import { bindActionCreators } from 'redux'
import { actionCreators } from "../../state/index";
import { useNavigate } from 'react-router-dom';
import { Button, TextField } from "@mui/material";
import API from "../../Services/API";
import { CircularProgress } from "@mui/material";
import SpinnerView from "../../Components/SpinnerView/SpinnerView";
import cancleRed from '../../Images/cancel-red.svg';
import ReportPopUpView from "../../Components/PopUpView/ReportPopUpView";
import PopUpView from "../../Components/PopUpView/PopUpView";
import './ScrambleLot.css';
import { useIdleTimer } from 'react-idle-timer'
import AlertSingleButtonModel from "../../Model/AlertSingleButtonModel";
import AlertView from "../../Components/AlertView/AlertView";
import AlertDualButtonModel from "../../Model/AlertDualButtonModel";
import { ReportQueue } from "../ListOfParkers/ReportQueue";
import CloseIcon from "../../Images/close-no-border.svg";

function ScrambleLot() {
    var dataAlertSingleButtonModel: AlertSingleButtonModel
    dataAlertSingleButtonModel = {}
    var dataAlertDualButtonModel: AlertDualButtonModel
    dataAlertDualButtonModel = {}
    const navigate = useNavigate();
    const state = useSelector((state: any) => state);
    const MultiSessionDetails = state.user.MultiSessionDetails;
    const SessionDetails = state.user.SessionDetails;
    const dispatch = useDispatch();
    /*const { addSession } = */bindActionCreators(actionCreators, dispatch);
    const [licensePlate, setLicensePlate] = useState("");
    const [showInputVehicleDetailView, setShowInputVehicleDetailView] = useState(true);
    const [showSpinnerView, setShowSpinnerView] = useState(false);
    const [showVerifiedView, setShowVerifiedView] = useState(false);
    const [showVerificationFailView, SetShowVerificationFailView] = useState(false);
    const [reportParkerPopUpView, setReportParkerPopUpView] = useState(false);
    const [confirmReport, setConfirmReport] = useState(false);
    const [spinner, setSpinner] = useState(false);
    const [reportedCount, setReportedCount] = useState(0);
    const timeout = 1000 * 60 * 15
    const [isIdle, setIsIdle] = useState(false)
    const [alertSingleData, setAlertSingleData] = useState(dataAlertSingleButtonModel);
    const [alertDualData, setAlertDualData] = useState(dataAlertDualButtonModel);
    const handleOnActive = () => setIsIdle(false)
    const handleOnIdle = () => setIsIdle(true);
    const api = new API();

    useIdleTimer({
        timeout,
        onActive: handleOnActive,
        onIdle: handleOnIdle
    })

    useEffect(() => {
        const set = setInterval(() => {
            if (isIdle) {
                setTimeout(() => {
                    clearInterval(set)
                    setAlertSingleData({
                        showAlert: true,
                        alertData: {
                            startImg: "",
                            title: "Session-Out",
                            subTitle: "Your session has expired due to 15 minutes of inactivity.",
                            okayButtonName: "Ok",
                            actionName: "Session-Out"
                        }
                    })
                }, 1000);
            }
        }, 1000);
        return () => clearInterval(set);
    }, [isIdle]);

    const [finishStatus, setfinishStatus] = useState(false);

    const onBackButtonEvent = (e: { preventDefault: () => void; }) => {
        e.preventDefault();
        if (!finishStatus) {
            setAlertDualData({
                showAlert: true,
                alertData: {
                    title: "Enforcement Check Complete",
                    subTitle: `Parking enforcement check for ${SessionDetails.propertyName} has been successfully completed. To proceed, please choose one of the following options:`,
                    startImg: "",
                    dualAButton: "End Session",
                    dualBButton: "New Session",
                    actionName: "End-Session",
                    closeButton: CloseIcon
                }
            });
        }
    };

    useEffect(() => {
        window.history.pushState(null, "", window.location.pathname);
        window.addEventListener('popstate', onBackButtonEvent);
        return () => {
            window.removeEventListener('popstate', onBackButtonEvent);
        };
    }, []);

    useEffect(() => {
        window.history.pushState(null, "", window.location.pathname);
        window.addEventListener('close', onBackButtonEvent);
        return () => {
            window.removeEventListener('close', onBackButtonEvent);
        };

    }, []);

    const getEndSession = async () => {
        await api.get_end_session(String(localStorage.getItem('end_session_guid')));
        // if (endSessionRes === 200) {
            localStorage.removeItem('end_session_guid');
            // localStorage.removeItem('access_token');
            navigate("/FindSpot");
        // }
    };

    const cachedScrambleParkers = useRef(false);
    const cacheListOfScrambleParkers = useCallback(async () => {
        if (cachedScrambleParkers.current) {
            return;
        }
        cachedScrambleParkers.current = true;
        const postDetails = {
            PropertyGuid: SessionDetails.propertyGuid,
            ZoneGuid: MultiSessionDetails.map((x: any) => x["selectedZoneGuid"])
        };
        setSpinner(true);
        const { result: freshListOfParkers, offline, error } = await api.get_list_of_scramble_parkers(postDetails);
        setSpinner(false);
        if (Array.isArray(freshListOfParkers) && !error && !offline) {
            localStorage.setItem('scramble_parkers', JSON.stringify(freshListOfParkers));
        }
    }, [MultiSessionDetails, SessionDetails.propertyGuid]);

    useEffect(() => {
        cacheListOfScrambleParkers();
    }, []);

    const getScrambleLotVerify = async () => {
        const cleanText = (text: string) => text.replace(/[^a-z0-9]/gi, '').toLowerCase();
        const verifyOfflineParker = (licensePlate: string) => {
            try {
                const scrambleParkers = JSON.parse(localStorage.getItem('scramble_parkers')!);
                const now = new Date();
                return scrambleParkers.find((p: any) =>
                    cleanText(p.licensePlate) === cleanText(licensePlate) &&
                    now >= new Date(p.startTimeUtc + "Z") &&
                    now <= new Date(p.endTimeUtc + "Z")
                ) !== undefined;
            } catch (_e) {
                return false;
            }
        }
        setShowSpinnerView(true);
        const postDetails = {
            PropertyGuid: SessionDetails.propertyGuid,
            ZoneGuid: MultiSessionDetails.map((x: any) => x["selectedZoneGuid"]),
            LicensePlate: licensePlate,
        };
        let verifiedParker = false;
        if (navigator.onLine) {
            const { status, offline } = await api.get_verify_scramble_parker(postDetails);
            if (offline || status > 400) {
                verifiedParker = verifyOfflineParker(licensePlate);
            } else {
                verifiedParker = status === 200;
            }
        } else {
            verifiedParker = verifyOfflineParker(licensePlate);
        }
        setShowSpinnerView(false);
        if (verifiedParker) {
            setShowInputVehicleDetailView(false);
            SetShowVerificationFailView(false);
            setShowVerifiedView(true);
        } else {
            SetShowVerificationFailView(true);
            setShowInputVehicleDetailView(false);
            setShowVerifiedView(false);
        }
    };

    const handleAlertOkayButton = (actionName: string) => {
        if (actionName === "ERROR-400") {
            setAlertSingleData({
                showAlert: false
            })
        } else if (actionName === "Session-Out") {
            setAlertSingleData({
                showAlert: false
            })
            getEndSession();
        }
    };

    const ShowokayAlert = () => {
        var startImg = (alertSingleData.alertData?.startImg) ? alertSingleData.alertData?.startImg : "";
        var Title = (alertSingleData.alertData?.title) ? alertSingleData.alertData?.title : "";
        var SubTitle = (alertSingleData.alertData?.subTitle) ? alertSingleData.alertData?.subTitle : "";
        var OkayButtonName = (alertSingleData.alertData?.okayButtonName) ? alertSingleData.alertData?.okayButtonName : "";
        var ActionName = (alertSingleData.alertData?.actionName) ? alertSingleData.alertData?.actionName : "";
        return (
            alertSingleData.showAlert && (
                <AlertView
                    startImg={startImg}
                    title={Title}
                    subTitle={SubTitle}
                    singleOkButton={OkayButtonName}
                    handleOkay={() => handleAlertOkayButton(ActionName)}
                />
            )
        );
    };

    const handleClose = () => {
        setAlertDualData({ showAlert: false });
    };

    const ShowDualAlert = () => {
        var Title = (alertDualData.alertData?.title) ? alertDualData.alertData?.title : "";
        var SubTitle = (alertDualData.alertData?.subTitle) ? alertDualData.alertData?.subTitle : "";
        var DualAButton = (alertDualData.alertData?.dualAButton) ? alertDualData.alertData?.dualAButton : "";
        var DualBButton = (alertDualData.alertData?.dualBButton) ? alertDualData.alertData?.dualBButton : "";
        var startImg = (alertDualData.alertData?.startImg) ? alertDualData.alertData?.startImg : "";
        var ActionName = (alertDualData.alertData?.actionName) ? alertDualData.alertData?.actionName : "";
        var closeButton = alertDualData.alertData?.closeButton;
        return (
            alertDualData.showAlert && (
                <AlertView
                    title={Title}
                    subTitle={SubTitle}
                    startImg={startImg}
                    closeButton={closeButton}
                    dualAButton={DualAButton}
                    dualBButton={DualBButton}
                    handleDualAButton={() => handleDualAButton(ActionName)}
                    handleDualBButton={() => handleDualBButton(ActionName)}
                    handleClose={handleClose}
                />
            )
        );
    };

    const getReportScrambleParker = async (licensePlate: any, makeTF: any, modelTF: any, colorTF: any) => {
        setSpinner(true);
        const PostData = {
            LicensePlate: licensePlate,
            Make: makeTF,
            Model: modelTF,
            Colour: colorTF,
            PropertyGuid: SessionDetails.propertyGuid
        };
        const { result: reportedCount, offline, error } = await api.get_report_scramble_parker(PostData);
        if (offline) {
            ReportQueue.set(`${SessionDetails.propertyGuid}_${licensePlate}`, {endpoint: "report_scramble_parker", body: PostData});
        }
        setSpinner(false);
        if (typeof reportedCount === 'number') {
            setReportedCount(reportedCount);
        }
        if (!offline) {
            if (error) {
                setAlertSingleData({
                    showAlert: true,
                    alertData: {
                        startImg: "",
                        title: "GrydPark",
                        subTitle: "Something went wrong. Please try restarting the app.",
                        okayButtonName: "Ok",
                        actionName: "ERROR-400"
                    }
                });
            } else {
                setReportParkerPopUpView(false);
                setConfirmReport(true);
            }
        } else {
            setReportParkerPopUpView(false);
            setAlertSingleData({
                showAlert: true,
                alertData: {
                    startImg: "",
                    title: "GrydPark",
                    subTitle: "Report queued due to offline mode. Connect to the Internet to make them effective.",
                    okayButtonName: "Ok",
                    actionName: "ERROR-400"
                }
            });
        }
    };

    const handleDualAButton = (actionName: string) => {
        setAlertDualData({ showAlert: false });
        if (actionName === "End-Session") {
          setfinishStatus(true);
          localStorage.removeItem('access_token');
          navigate('/');
        }
    };

    const handleDualBButton = (actionName: string) => {
        setAlertDualData({ showAlert: false });
        if (actionName === "End-Session") {
            setfinishStatus(true);
            getEndSession();
        }
    }

    function threeCharValidation(value: string) {
        return (value.length >= 3);
    }

    return (
        <div className="adjust-screen" style={{
            display: "grid",
            placeItems: "center",
            height: "100vh",
        }}>
            {showSpinnerView && (
                <div style={{ 'top': "50vh", position: "absolute" }}>
                    <CircularProgress />
                </div>
            )}
            {(spinner) && <SpinnerView />}
            {ShowokayAlert()}
            {ShowDualAlert()}
            <div style={{
                overflowY: 'auto',
                overflow: 'auto',
                height: '100%',
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "center",
                width: "100%",
            }}>
                <div className="BaseContainer">
                    {
                        reportParkerPopUpView && (
                            <ReportPopUpView
                                title={"Report Parker"}
                                // subTitle={"Please enter the illegal parker’s information to complete the report. "}
                                showReportMenu={true}
                                showSpotTF={false}
                                licensePlate={licensePlate}
                                licensePlateEditable={true}
                                handelClose={() => {
                                    setReportParkerPopUpView(false);
                                }}
                                handelOkButton={(licensePlate: any, makeTF: any, modelTF: any, colorTF: any) => {
                                    getReportScrambleParker(licensePlate, makeTF, modelTF, colorTF);
                                }}
                            // spotNumberTF = {"1"}

                            ></ReportPopUpView>
                        )
                    }
                    {
                        confirmReport && (
                            <PopUpView
                                startImg={require('../../Images/tick.png')}
                                title={"Report Submitted"}
                                subTitle={[<span key="s1">This parker has been reported </span>, <b key="s2" className="pop-up-sub-title" style={{ color: reportedCount === 0 ? "#00AF85" : "#F89C83", fontWeight: '900' }}>{reportedCount + " times."}</b>]}
                                subTitle2={[<b key="b1">Recommended Action:</b>, <br key="b2" />, <span key="b3">Leave a GrydPark warning ticket on the windshield.</span>]}
                                subTitle3={"Tap continue to keep scanning or end the session."}
                                dualAButton={"End Session"}
                                dualBButton={"Continue"}
                                handelDualAButton={() => {
                                    navigate(-1)
                                }}
                                handelDualBButton={() => {
                                    SetShowVerificationFailView(false);
                                    setShowInputVehicleDetailView(true);
                                    setConfirmReport(false);
                                    setLicensePlate("");

                                }}
                                handelClose={() => {
                                    setConfirmReport(false)
                                }}
                            />
                        )
                    }
                    <div className='Sub-Container-View'>
                        <div style={{ 'marginTop': '20px' }} className='RightView'>
                            <Button
                                onClick={() => {
                                    setAlertDualData({
                                        showAlert: true,
                                        alertData: {
                                            title: "Enforcement Check Complete",
                                            subTitle: `Parking enforcement check for ${SessionDetails.propertyName} has been successfully completed. To proceed, please choose one of the following options:`,
                                            startImg: "",
                                            dualAButton: "End Session",
                                            dualBButton: "New Session",
                                            actionName: "End-Session",
                                            closeButton: CloseIcon
                                        }
                                    })
                                    //getEndSession();
                                }}
                            > End Session</Button>
                        </div>
                        <div className='SelectZone-min-hight' style={{
                            marginTop: '20px',
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            flexDirection: "column",
                            }}
                        >
                            <div className="CenterView">
                                <h2 className="title">{SessionDetails.propertyName}</h2>
                            </div>
                            <div style={{ 'height': '100%' }} className="BoxView">
                                {
                                    showInputVehicleDetailView && (
                                        <div className="CenterView">
                                            <h1 className="title-header">Vehicle Details</h1>
                                            <h1 style={{ width: '80%', textAlign: 'center' }} className="pop-up-sub-title">Enter license plate and hit continue to begin enforcement checks.</h1>
                                            <div style={{ width: '80%', marginTop: '20px' }}>
                                                <TextField fullWidth className="MatrialTextFieldFull outlined-basic" size="medium" label="License Plate" variant="outlined" color="primary" autoComplete="off" value={licensePlate}
                                                    inputProps={{ maxLength: 9 }}
                                                    onChange={(e: any) => {
                                                        let firstLetter = e.target.value.charAt(0);
                                                        e.target.value = firstLetter.toUpperCase() + e.target.value.slice(1);
                                                        setLicensePlate(e.target.value)
                                                    }}
                                                    onFocus={() => {

                                                        // setFooterPosition("relative")
                                                    }}
                                                    onBlur={() => {
                                                        // setFooterPosition("fixed")

                                                    }}

                                                />

                                            </div>
                                        </div>
                                    )
                                }

                                {
                                    showVerifiedView && (
                                        <div className="CenterView">
                                            <img style={{ marginTop: '30px' }} src={require('../../Images/tick.png')} alt="" className="start-icon"
                                                onClick={() => {

                                                }}
                                            />
                                            <h1 className="title-header">Verified Parker</h1>
                                            <h1 style={{ width: '80%', textAlign: 'center' }} className="pop-up-sub-title">
                                                Parker is verified.<br />Scan new plate or end session.</h1>

                                        </div>
                                    )

                                }
                                {
                                    showVerificationFailView && (
                                        <div className="CenterView">
                                            <img style={{ marginTop: '30px' }} src={cancleRed} alt="" className="start-icon"
                                                onClick={() => {

                                                }}
                                            />
                                            <h1 className="title-header">Illegal Parker</h1>
                                            <h1 style={{ width: '80%', textAlign: 'center' }} className="pop-up-sub-title">
                                                This parker is not registered.<br />Report parker or continue scanning.</h1>
                                        </div>
                                    )

                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='BaseContainer' style={{ 'marginTop': '10px', width: "100%" }}>
                <div style={{ width: '85%' }}>
                    {
                        (showVerificationFailView) && (

                            <div style={{ 'justifyContent': 'space-around', width: '100%' }} className="RowView">
                                <div style={{ width: '45%' }}>
                                    <Button style={{ 'width': '100%', 'height': '48px', 'backgroundColor': '#FFFFFF', 'fontSize': "15px", 'fontFamily': 'Manrope', 'fontWeight': '700', 'borderRadius': '12px' }} variant="outlined"
                                        onClick={() => {
                                            // _props.handelDualAButton()
                                            SetShowVerificationFailView(false)
                                            setShowVerifiedView(false)
                                            setShowInputVehicleDetailView(true);
                                            setLicensePlate("")
                                        }}
                                    >
                                        Continue
                                    </Button>
                                </div>
                                <div style={{ width: '45%' }}>
                                    <Button style={{ width: '100%', height: '48px', backgroundColor: '#00AF85', fontSize: "15px", fontFamily: 'Manrope', fontWeight: '700', borderRadius: '12px', marginBottom: '20px' }} variant="contained"
                                        onClick={() => {
                                            setReportParkerPopUpView(true);
                                        }
                                        }
                                    >
                                        Report Parker
                                    </Button>
                                </div>
                            </div>
                        ) || (
                            <Button style={threeCharValidation(licensePlate) ? { width: '100%', height: '48px', backgroundColor: '#00AF85', fontSize: "15px", fontFamily: 'Manrope', fontWeight: '700', borderRadius: '12px' } : { width: '100%', height: '48px', backgroundColor: '#8A959E', fontSize: "15px", fontFamily: 'Manrope', fontWeight: '700', borderRadius: '12px' }} variant="contained"
                                onClick={() => {
                                    if (threeCharValidation(licensePlate)) {
                                        if (showVerifiedView) {
                                            setShowVerifiedView(false)
                                            setShowInputVehicleDetailView(true);
                                            setLicensePlate("")
                                        } else {
                                            getScrambleLotVerify();
                                            // setShowInputVehicleDetailView(false)
                                            // setShowSpinnerView(true)
                                            // setTimeout(() => {
                                            //     setShowSpinnerView(false)
                                            //     if (licensePlate == "1111") {
                                            //         setShowVerifiedView(true)
                                            //         SetShowVerificationFailView(false)
                                            //     } else {
                                            //         setShowVerifiedView(false)
                                            //         SetShowVerificationFailView(true)
                                            //     }

                                            // }, 2000);
                                        }
                                    }
                                }
                                }
                            >
                                Continue
                            </Button>
                        )
                    }
                </div>
            </div>
        </div>
    )
}

export default ScrambleLot;
