import localforage from "localforage";

export namespace ReportQueueT {
  export type Endpoint = "report_parker" | "report_scramble_parker";
  export type Request = { body: any, endpoint: Endpoint };
  export type Iter = {request: Request, key: string, index: number};
}

var reportQueue = localforage.createInstance({
  name: "parking",
  storeName: "reportQueue",
});

export abstract class ReportQueue {         
  private static queue = "parkers";

  public static async set(spotGuid: string, request: ReportQueueT.Request): Promise<ReportQueueT.Request> {
    return reportQueue.setItem(spotGuid, request);
  }

  public static async get(spotGuid: string): Promise<ReportQueueT.Request | null> {
    return reportQueue.getItem(spotGuid);
  }
  
  public static async remove(spotGuid: string): Promise<void> {
    return reportQueue.removeItem(spotGuid);
  }
  
  public static async count(): Promise<number> {
    return reportQueue.length();
  }

  public static async iterate(iteratee: (value: ReportQueueT.Request, key: string, iterationNumber: number) => void): Promise<void> {
    return reportQueue.iterate(iteratee);
  }
}
