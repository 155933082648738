import React, { useState, useEffect, useCallback } from "react";
import "./NetworkStatus.css";
import { ReportQueue, ReportQueueT } from "../../Screens/ListOfParkers/ReportQueue";
import API, { FetchApi } from "../../Services/API";

function NetworkStatus() {
  const [status, setStatus] = useState(navigator.onLine);
  const [syncing, setSyncing] = useState<{ current: number, total: number } | null>(null);
  const [retrySync, setRetrySync] = useState(false);

  const runReportQueue = useCallback(async () => {
    setRetrySync(false);
    if (syncing) {
      return;
    }
    const total = await ReportQueue.count();
    if (total > 0) {
      const api = new API();
      const fetchEndpoint: { [k in ReportQueueT.Endpoint]: FetchApi } = {
        report_parker: api.get_report_parker,
        report_scramble_parker: api.get_report_scramble_parker,
      };
      const pending: ReportQueueT.Iter[] = [];
      let canRetry = false;

      const run = async ({request, key}: ReportQueueT.Iter) => {
        const { status, error } = await fetchEndpoint[request.endpoint](request.body);
        if (status === 200 && !error) {
          await ReportQueue.remove(key);
        } else {
          canRetry = true;
        }
        setSyncing(val => ({ total, current: (val?.current || 0) + 1 }));
      };

      setSyncing({ total, current: 0 });
      console.log("Syncing report queue...");

      await ReportQueue.iterate((request, key, index) => {
        pending.push({request, key, index});
      });

      await Promise.all(pending.map(item => run(item)));

      if (!canRetry) {
        setTimeout(() => setSyncing(null), 1500);
      } else {
        setRetrySync(true);
        setSyncing(null);
      }
      document.dispatchEvent(new Event("onReportQueueSyncCompleted"));
    }
  }, [syncing]);

  useEffect(() => {
    const onlineEventHandler =  () => {
      console.log("Connected to network.");
      setStatus(true);
      runReportQueue();
    };
    const offlineEventHandler = () => {
      console.log("Network connection lost.");
      setStatus(false);
    };
    window.addEventListener("online", onlineEventHandler);
    window.addEventListener("offline", offlineEventHandler);
    return () => {
      window.removeEventListener("online", onlineEventHandler);
      window.removeEventListener("offline", offlineEventHandler);
    }
  }, [status, runReportQueue]);

  return (
    <div className={`network-status ${status ? (syncing || retrySync ? "syncing" : "") : "offline"}`}>
      {syncing ?
        `Syncing ${syncing.current}/${syncing.total}`
      : retrySync ?
        <button type="button" onClick={runReportQueue}>Retry sync</button>
      :
        "Offline"
      }
    </div>
  );
}

export default NetworkStatus;
