import reasonToReport from '../Json/reportselect.json'

export const HEADERS = () =>
  new Headers({
    "content-type": "application/json",
    'Authorization': localStorage.getItem('access_token') ? 'Bearer ' + localStorage.getItem('access_token') : ''
  }
);
export const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
export const StripKey = process.env.REACT_APP_STRIPE_KEY;

const fetchDefault: typeof fetch = async (...args) => {
  const options = args[1] || {};
  options.headers = HEADERS();
  console.debug(`${options?.method} ${args[0].toString().replace(API_ENDPOINT!, "")}`);
  try {
    return fetch(...args);
  } catch (e) {
    console.debug("API fetch error: request aborted or network is offline", e);
    throw e;
  };
};

export type FetchApiOptions<T> = {
  method?: "GET" | "POST" | "PUT" | "PATCH" | "DELETE",
  path: string,
  endpoint?: string,
  body?: BodyInit | null | undefined,
  emptyResult?: T
};
export type FetchApiReturn<T> = {
  result: T,
  status: number,
  offline: boolean,
  error?: unknown
};
export type FetchApiFunction = <T,>(options: FetchApiOptions<T>) => Promise<FetchApiReturn<T>>;
export type FetchApi = (payload: any) => Promise<any>;

const fetchApi: FetchApiFunction = async <T,>({ method, endpoint, path, body, emptyResult }: FetchApiOptions<T>) => {
  try {
    const target = endpoint || API_ENDPOINT;
    const res = await fetchDefault(`${target}${path}`, {
      method: method || "GET",
      body: body ? JSON.stringify(body) : undefined,
    });
    if (res.status === 200) {
      let result;
      try {
        result = await res.clone().json();
      } catch (_e) {
        result = await res.text();
      }
      return { result: result as T, status: res.status, offline: false, error: undefined };
    }
    return { result: emptyResult as T, status: res.status, offline: false, error: res.text() };
  } catch (error) {
    return { result: emptyResult as T, status: 0, offline: true, error };
  }
};

export default class API {

  get_search_property = async (payload: any) => {
    return fetchApi<[]>({
      method: "POST",
      path: "/spot/parker-enforcement/search-property",
      body: payload,
      emptyResult: [],
    });
  }

  get_access_token = async (payload: any) => {
    return fetchApi<{ accessToken: string }>({
      method: "POST",
      path: "/enforcement/signin",
      body: payload,
    });
  }

  get_list_of_parkers = async (payload: any) => {
    return fetchApi<[]>({
      method: "POST",
      path: "/spot/parker-enforcement/get-parkers",
      body: payload,
      emptyResult: [],
    });
  }

  get_list_of_scramble_parkers = async (payload: any) => {
    return fetchApi<[]>({
      method: "POST",
      path: "/spot/parker-enforcement/get-scramble-parkers",
      body: payload,
      emptyResult: [],
    });
  }

  get_reason_list = async () => {
    return fetchApi<[]>({
      method: "GET",
      path: "/spot/parker-enforcement/get-reason",
      emptyResult: [],
    });
  }

  get_end_session = async (payload: string) => {
    return fetchApi({
      method: "GET",
      path: `/spot/parker-enforcement/end-session/${payload}`,
    });
  }

  get_zone = async (payload: any) => {
    return fetchApi<[]>({
      method: "POST",
      path: "/spot/parker-enforcement/start-session",
      body: payload,
      emptyResult: [],
    });
  }

  get_report_parker = async (payload: any) => {
    return fetchApi<number>({
      method: "POST",
      path: "/spot/parker-enforcement/report-parker",
      body: payload,
      emptyResult: 0,
    });
  }

  get_report_scramble_parker = async (payload: any) => {
     return fetchApi<number>({
      method: "POST",
      path: "/spot/parker-enforcement/report-scramble-parker",
      body: payload,
      emptyResult: 0,
    });
  }

  get_history = async (payload: string) => {
    return fetchApi<[]>({
      method: "GET",
      path: `/spot/parker-enforcement/get-history/${payload}`,
      emptyResult: [],
    });
  }

  get_Scramble_Lot_Verify = async (payload: string) => {
    return fetchApi<[]>({
      method: "GET",
      path: `/spot/parker-enforcement/verify-parker/${payload}`,
    });
  }

  get_verify_scramble_parker = async (payload: any) => {
    return fetchApi<[]>({
      method: "POST",
      path: `/spot/parker-enforcement/verify-scramble-parker`,
      body: payload,
    });
  }

  get_reason = (callback: any) => {
    callback(reasonToReport);
  }
}
