import React, { useEffect, useState } from 'react';
import HeaderView from "../../Components/HeaderView/HeaderView";
import { Button, TextField } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import API from "../../Services/API";
import SpinnerView from "../../Components/SpinnerView/SpinnerView";
import AlertView from "../../Components/AlertView/AlertView";
import AlertSingleButtonModel from "../../Model/AlertSingleButtonModel";
import {VisibilityOffOutlined, VisibilityOutlined} from "@mui/icons-material";
import Logo from '../../Images/logo.svg';
import './Login.css';

function Login() {
  var dataAlertSingleButtonModel: AlertSingleButtonModel = {};
  const api = new API();
  const [emailAddress, setEmailAddress] = useState("");
  const [password, setPassword] = useState("");
  const [togglePassword, setTogglePassword] = useState(false);
  const [validEmail, setValidEmail] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const navigate = useNavigate();
  const validateEmail = (email: string) => {
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    setValidEmail(re.test(String(email).toLowerCase()));
  }
  const [alertSingleData, setAlertSingleData] = useState(dataAlertSingleButtonModel);

  const handleAlertOkayButton = (actionName: string) => {
    if (actionName === "CLOSE-ALERT") {
      setAlertSingleData({
        showAlert: false
      });
    }
  };

  useEffect(() => {
    validateEmail(emailAddress);
  },);

  const get_validationColor = () => {
    if (validEmail && password.length) {
      return '#00AF85';
    } else {
      return '#8A959E';
    }
  };

  const ShowokayAlert = () => {
    var startImg = (alertSingleData.alertData?.startImg) ? alertSingleData.alertData?.startImg : "";
    var Title = (alertSingleData.alertData?.title) ? alertSingleData.alertData?.title : "";
    var SubTitle = (alertSingleData.alertData?.subTitle) ? alertSingleData.alertData?.subTitle : "";
    var OkayButtonName = (alertSingleData.alertData?.okayButtonName) ? alertSingleData.alertData?.okayButtonName : "";
    var ActionName = (alertSingleData.alertData?.actionName) ? alertSingleData.alertData?.actionName : "";
    return (
      alertSingleData.showAlert && (
        <AlertView
          startImg={startImg}
          title={Title}
          subTitle={SubTitle}
          singleOkButton={OkayButtonName}
          handleOkay={() => handleAlertOkayButton(ActionName)}
        />
      )
    );
  };

  const showLoginError = (message: string) => {
    setAlertSingleData({
      showAlert: true, alertData: {
        startImg: "",
        title: "GrydPark",
        subTitle: message,
        okayButtonName: "Ok",
        actionName: "CLOSE-ALERT"
      }
    });
  };

  return (
    <div className="adjust-screen" style={{
      display: 'flex',
      height: '100vh',
      justifyContent: 'center',
      alignItems: 'center'
    }}>
      {spinner && <SpinnerView />}
      {ShowokayAlert()}
      <div className="BaseContainer">
        <img src={Logo} alt=""/>
        <HeaderView title={"Welcome Back!"} subtitle={"Log in to your account to begin an enforcement check."}></HeaderView>
        <div className='Sub-Container-View'>
          <label className="FieldLabel">Email Address</label>
          <TextField fullWidth className="MatrialTextFieldFull outlined-basic" size="medium" label="Email address" variant="outlined" color="primary" autoComplete="off" value={emailAddress}
            inputProps={{ maxLength: 200, }}
            onChange={(e: any) => {
              validateEmail(e.target.value)
              setEmailAddress(e.target.value)
            }}
          />
          <label className="FieldLabel">Password</label>
          <TextField fullWidth className="MatrialTextFieldFull outlined-basic" size="medium" label="Password" variant="outlined" color="primary" autoComplete="off" value={password}
            inputProps={{ maxLength: 30 }}
            type={togglePassword ? "text" : "password"}
            onChange={(e: any) => {
              setPassword(e.target.value)
            }}
            InputProps={{
                endAdornment: (
                  <Button
                    endIcon={togglePassword ? <VisibilityOffOutlined color="secondary"/> : <VisibilityOutlined color="secondary"/>}
                    onClick={() => {
                      setTogglePassword((prevState) => !prevState)
                    }}
                  />)
              }}
          />

          <Button
            style={{
              width: '100%',
              height: '48px',
              backgroundColor: get_validationColor(),
              fontSize: "15px",
              fontFamily: 'Manrope',
              fontWeight: '700',
              borderRadius: '8px'
            }}
            variant="contained"
            onClick={
              async () => {
                if (!navigator.onLine) {
                  showLoginError("You need an internet connection to perform this action.");
                  return;
                }
                if (validEmail && password.length >= 3) {
                  const PostData = {
                    UserName: emailAddress,
                    Password: password,
                    IsMobile: false
                  };
                  setSpinner(true);
                  const {result: responseAccess, offline, error } = await api.get_access_token(PostData);
                  setSpinner(false);
                  if (offline) {
                    showLoginError("Connection error: make sure you're connected to the Internet.");
                    return;
                  }
                  if (error || !responseAccess.accessToken) {
                    showLoginError("Invalid credentials.");
                    return;
                  }
                  if (responseAccess.accessToken) {
                    localStorage.setItem('access_token', responseAccess.accessToken);
                    navigate('/FindSpot', { state: {} });
                  }
                }
              }
            }
          >Log In
          </Button>
        </div>
      </div>
    </div>
  );
}
export default Login;
