import React, { useCallback, useEffect, useRef, useState } from 'react';
import HeaderView from "../../Components/HeaderView/HeaderView";
import Autocomplete from '@mui/material/Autocomplete';
import { Button, IconButton, TextField } from "@mui/material";
import InputAdornment from '@mui/material/InputAdornment';
import LocArrowsvg from '../../Images/locationarrow-svg.svg';
import closesvg from "../../Images/close.svg";
import { makeStyles } from '@mui/styles';
import { useDispatch } from "react-redux";
import { bindActionCreators } from 'redux';
import { actionCreators } from "../../state/index";
import API from "../../Services/API";
import { useNavigate } from 'react-router-dom';
import './SpotSearch.css';
import SpinnerView from "../../Components/SpinnerView/SpinnerView";
import AlertView from "../../Components/AlertView/AlertView";
import AlertSingleButtonModel from "../../Model/AlertSingleButtonModel";
import AlertDualButtonModel from "../../Model/AlertDualButtonModel";
import { asyncDebounce } from '../../Common/Debounce';

function randstr(prefix?: string) {
    return Math.random().toString(36).replace('0.', prefix || '');
}

const useStyles = makeStyles({
    root: {
        '&.MuiAutocomplete-popper': {
            paddingTop: 15,
        }
    },
    inputRoot: {
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            // borderColor: "#00AF85 !important",
        },
        "&.MuiAutocomplete-popper": {
            paddingTop: 15,
        }
    },
    popper: {
        paddingTop: 15,
    }
});

function SpotSearch() {
    var dataAlertSingleButtonModel: AlertSingleButtonModel = {};
    var dataAlertDualButtonModel: AlertDualButtonModel = {};
    const navigate = useNavigate();
    const classes = useStyles();
    const [waringBorder, setWaringBorder] = useState(false)
    const [locations, setLocations] = useState<any[]>([]);
    const [PropertyName, setPropertyName] = useState("");
    const [useGeolocation, setUseGeolocation] = useState(true);
    const [lat, setLat] = useState<number>();
    const [lng, setLng] = useState<number>();
    const [locationDetails, setLocationDetails] = useState<{}>();
    const [helperText, setHelperText] = useState<string>();
    const [alertSingleData, setAlertSingleData] = useState(dataAlertSingleButtonModel);
    const [alertDualData, setAlertDualData] = useState(dataAlertDualButtonModel);
    const dispatch = useDispatch();
    const { addSession } = bindActionCreators(actionCreators, dispatch);
    const api = new API();
    const [spinner, setSpinner] = useState(false);
    const [waringReason, setWaringReason] = useState<string>();

    useEffect(() => {
        if (!localStorage.getItem('access_token')) {
            navigate("/");
        }
    }, [navigate]);

    const [finishStatus, setfinishStatus] = useState(false);

    const [networkStatus] = useState(navigator.onLine);
    useEffect(() => {
        const onlineEventHandler = () => {
            // Search again automatically if last search failed due to being offline
            if (waringBorder) {
                const input = document.querySelector("#location-input");
                const inputEvent = new Event("input", { bubbles: true });
                const nativeInputValue = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, "value");
                const currentValue = nativeInputValue?.get?.call(input);
                nativeInputValue?.set?.call(input, "");
                input?.dispatchEvent(inputEvent);
                nativeInputValue?.set?.call(input, currentValue);
                input?.dispatchEvent(inputEvent);
            }
        };
        window.addEventListener("online", onlineEventHandler);
        return () => window.removeEventListener("online", onlineEventHandler);
    }, [networkStatus, waringBorder]);

    const onBackButtonEvent = (e: Event) => {
        e.preventDefault();
        if (!finishStatus) {
            setAlertDualData({
                showAlert: true,
                alertData: {
                    title: "Are you sure?",
                    subTitle: "Do you want to Log out?",
                    startImg: "",
                    dualAButton: "Yes",
                    dualBButton: "No",
                    actionName: "Log-Out",
                }
            });
        }
    };

    useEffect(() => {
        window.history.pushState(null, "", window.location.pathname);
        window.addEventListener('popstate', onBackButtonEvent);
        return () => {
            window.removeEventListener('popstate', onBackButtonEvent);
        };
    }, []);

    useEffect(() => {
        window.history.pushState(null, "", window.location.pathname);
        window.addEventListener('close', onBackButtonEvent);
        return () => {
            window.removeEventListener('close', onBackButtonEvent);
        };
    }, []);

    const handleAlertOkayButton = (actionName: string) => {
        if (actionName === "Log-Out") {
            setAlertDualData({ showAlert: false });
        } else if (actionName === "Offline") {
            setAlertSingleData({ showAlert: false });
        }
    };

    const handleDualAButton = (actionName: string) => {
        setAlertDualData({ showAlert: false });
        if (actionName === "Log-Out"){
            navigate("/");
        }
    };

    const handleDualBButton = (actionName: string) => {
        setAlertDualData({ showAlert: false });
        if (actionName === "Log-Out"){
            setfinishStatus(false);
        }
    };

    const ShowokayAlert = () => {
        var startImg = (alertSingleData.alertData?.startImg) ? alertSingleData.alertData?.startImg : ""
        var Title = (alertSingleData.alertData?.title) ? alertSingleData.alertData?.title : ""
        var SubTitle = (alertSingleData.alertData?.subTitle) ? alertSingleData.alertData?.subTitle : ""
        var OkayButtonName = (alertSingleData.alertData?.okayButtonName) ? alertSingleData.alertData?.okayButtonName : ""
        var ActionName = (alertSingleData.alertData?.actionName) ? alertSingleData.alertData?.actionName : ""
        return (
            alertSingleData.showAlert &&
            <AlertView
                startImg={startImg}
                title={Title}
                subTitle={SubTitle}
                singleOkButton={OkayButtonName}
                handleOkay={() => handleAlertOkayButton(ActionName)}
            />
        );
    };

    const ShowDualAlert = () => {
        const Title = (alertDualData.alertData?.title) ? alertDualData.alertData?.title : "";
        const SubTitle = (alertDualData.alertData?.subTitle) ? alertDualData.alertData?.subTitle : "";
        const DualAButton = (alertDualData.alertData?.dualAButton) ? alertDualData.alertData?.dualAButton : "";
        const DualBButton = (alertDualData.alertData?.dualBButton) ? alertDualData.alertData?.dualBButton : "";
        const startImg = (alertDualData.alertData?.startImg) ? alertDualData.alertData?.startImg : "";
        const ActionName = (alertDualData.alertData?.actionName) ? alertDualData.alertData?.actionName : "";
        return (
            alertDualData.showAlert && (
                <AlertView
                    title={Title}
                    subTitle={SubTitle}
                    startImg={startImg}
                    dualAButton={DualAButton}
                    dualBButton={DualBButton}
                    handleDualAButton={() => handleDualAButton(ActionName)}
                    handleDualBButton={() => handleDualBButton(ActionName)}
                />
            )
        )
    }

    const debouncedSendRequest = useRef(asyncDebounce(api.get_search_property, 400));

    const addSessionDetails = useCallback((location: any) => {
        if (location) {
            setPropertyName(location["propertyAddressLine1"])
            const SessionDetails = {
                curLatitude: location["propertyLatitiude"],
                curLongitude: location["propertyLongitude"],
                propertyGuid: location["propertyGuid"],
                propertyName: location["propertyAddressLine1"],
                selectedZone: ""
            }
            addSession(SessionDetails);
        }
        setHelperText(undefined);
    }, [setPropertyName, addSession]);

    const startScanning = () => {
        if (!navigator.onLine) {
            setAlertSingleData({
                showAlert: true,
                alertData: {
                    startImg: "",
                    title: "GrydPark",
                    subTitle: "You need an internet connection to perform this action.",
                    okayButtonName: "Ok",
                    actionName: "Offline"
                }
            });
            return;
        }
        if (PropertyName.length >= 3) {
            navigate('/SelectZone', { state: {} });
        }
    };

    const propertySearchField = async (event: any, value: string) => {
        if (event) {
            const index = event.currentTarget.getAttribute("data-option-index");
            setLocations([]);
            setPropertyName("");
            if (event.target && event.target.getAttribute("role") === "option" && (event.target.value === 0 || event.target.value > 0)) {
                if (locations && locations.length > 0 && locations[event.target.value]["propertyAddressLine1"]) {
                    addSessionDetails(locations[index]);
                }
            } else {
                if (value.length >= 3) {
                    const searchDetails_ = { SearchKeyword: value };
                    setSpinner(true);
                    const { result: propertyLocation_, offline, error } = await debouncedSendRequest.current(searchDetails_);
                    setSpinner(false);
                    if (offline) {
                        setWaringBorder(true);
                        setWaringReason("Connect to the Internet to find locations.");
                    } else if (error) {
                        setWaringBorder(true);
                        setWaringReason("Error trying to find location.");
                    } else if (propertyLocation_) {
                        if (propertyLocation_.length === 0) {
                            setWaringBorder(true);
                            setWaringReason("Location not found.");
                        } else {
                            setWaringBorder(false);
                            setWaringReason(undefined);
                        }
                        setLocations(propertyLocation_);
                    }
                } else {
                    setWaringBorder(false);
                }
                setTimeout(() => {
                    if (value.length === 0) {
                        setWaringBorder(false);
                    }
                }, 1000);
            }
        }
    };

    const getUserLocation = useCallback(() => {
        if (useGeolocation && ('geolocation' in navigator)) {
            setHelperText("Getting your current location...");
            if (!locationDetails) {
                setSpinner(true);
            }
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    setLat(latitude);
                    setLng(longitude);
                },
                (positionError) => {
                    switch (positionError.code) {
                        case positionError.PERMISSION_DENIED:
                            console.debug("Position: denied by user");
                            break;
                        case positionError.POSITION_UNAVAILABLE:
                            console.debug("Position: unavailable");
                            break;
                        case positionError.TIMEOUT:
                            console.debug("Position: timeout");
                            break;
                        default:
                            console.debug("Position: unknown error");
                    }
                    resetLocations();
                },
                {
                    enableHighAccuracy: true
                }
            );
        }
    }, [useGeolocation, locationDetails]);

    const getNearestProperty = useCallback(async () => {
        if (lat && lng && useGeolocation && !locationDetails) {
            setSpinner(true);
            const { result: propertyLocation_, offline, error } = await debouncedSendRequest.current({
                SearchLatitiude: lat,
                SearchLongitude: lng
            });
            setSpinner(false);
            // @ts-ignore
            setLocationDetails(propertyLocation_?.[0] || null);
        }
    }, [api, lat, lng, useGeolocation, locationDetails]);

    useEffect(() => {
        if (useGeolocation && locationDetails === undefined) {
            getUserLocation();
            getNearestProperty().then();
        }
    }, [useGeolocation, getUserLocation, getNearestProperty, locationDetails]);

    useEffect(() => {
        if (useGeolocation && locationDetails) {
            addSessionDetails(locationDetails);
        }
    }, [locationDetails, addSessionDetails, useGeolocation]);

    const resetLocations = useCallback(() => {
        if (useGeolocation) {
            setLocationDetails(undefined);
            setUseGeolocation(false);
            setPropertyName("");
            setLocations([]);
            setSpinner(false);
            setHelperText(undefined);
        }
    }, [useGeolocation]);

    return (
        <div className="adjust-screen" style={{
            display: 'grid',
            placeContent: 'center',
            placeItems: 'center',
            height: '100vh',
        }}>
            { spinner && <SpinnerView helperText={helperText} /> }
            { ShowokayAlert() }
            { ShowDualAlert() }
            <div style={{
                overflow: 'auto',
                display: 'flex',
                justifyContent: 'center',
                marginTop: "-50%",
            }}>
                <div className="BaseContainer">
                    <HeaderView title={"New Session"} showBackBT={false}></HeaderView>
                    <div style={{
                        display: "grid",
                        justifyItems: "center",
                    }}>
                        <div className='slogan-container' style={{ width: "85%" }}>
                            <small className='slogan-title'>To start the enforcement session, enter your location.</small>
                        </div>
                        <div style={{
                            marginTop: '6vh',
                            marginBottom: '6vh',
                            width: "100%",
                        }}>
                            <Autocomplete
                                id="location-input"
                                freeSolo
                                options={locations.map((option: any) => option.propertyAddressLine1)}
                                clearIcon={<img className="Arrow-Image" src={closesvg} alt=""></img>}
                                onChange={(_event, _value, reason) => {
                                    if (reason === "clear") {
                                        setWaringBorder(false);
                                        resetLocations();
                                    }
                                }}
                                classes={{ ...classes, option: classes.popper }}
                                componentsProps={{
                                    paper: {
                                        sx: {
                                            borderRadius: "12px",
                                        }
                                    },
                                }}
                                ListboxProps={{
                                    style: {
                                        border: '1px solid #8A959E',
                                        borderRadius: "12px",
                                        lineHeight: "20px",
                                    }
                                }}
                                renderInput={(params) =>
                                    <TextField
                                        color={waringBorder ? 'warning' : 'primary'}
                                        className={"MatrialTextFieldFull"}
                                        placeholder="Enter Your Location"
                                        onChange={() => resetLocations()}
                                        onFocus={()=> getUserLocation()}
                                        {...params}
                                        InputProps={{
                                            ...params.InputProps,
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <IconButton onClick={() => {
                                                        setUseGeolocation(true);
                                                        setLocationDetails(undefined);
                                                        setWaringBorder(false);
                                                    }}>
                                                        <img className="Arrow-Image" src={LocArrowsvg} alt=""/>
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                }
                                value={PropertyName}
                                renderOption={(props, option) => <li {...props} key={`${option}-${randstr()}`}>{option}</li>}
                                onInputChange={propertySearchField}
                            />
                            {waringBorder && <small className='warning-title'>{waringReason}</small>}
                        </div>
                    </div>
                </div>
            </div>
            <div className='BaseContainer' style={{ 'marginTop': '10px' }}>
                <div style={{ width: '85%' }}>
                    <Button
                        style={{
                            width: '100%',
                            height: '48px',
                            backgroundColor: PropertyName.length >= 3 ? '#00AF85' : '#8A959E',
                            fontSize: "15px",
                            fontFamily: 'Manrope',
                            fontWeight: '700',
                            borderRadius: '8px'
                        }}
                        variant="contained"
                        onClick={startScanning}
                        onMouseDown={startScanning}
                    >Start Scanning
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default SpotSearch;
