import { CircularProgress } from "@mui/material";
import React from "react";
import './SpinnerView.css'

const SpinnerView = (props: any) => {
    const { helperText } = props;

    return (

        <div className="Spinner_Screen">
            <div className="SubView">
                <CircularProgress />
                {helperText && (
                    <p className="slogan-title">{helperText}</p>
                )}
            </div>
        </div>

    )

}
export default SpinnerView