import { Button } from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import Back from '../../Images/back.png'
import './ParkerDetail.css'
import { useNavigate, useLocation } from 'react-router-dom';
import API from "../../Services/API";
import { useSelector } from "react-redux"
// import { useDispatch } from "react-redux";
// import { bindActionCreators } from 'redux'
// import { actionCreators } from "../../state/index"
import { useIdleTimer } from 'react-idle-timer'
import moment from 'moment';
import AlertView from "../../Components/AlertView/AlertView";
import AlertSingleButtonModel from "../../Model/AlertSingleButtonModel";
import AlertDualButtonModel from "../../Model/AlertDualButtonModel";
import CloseIcon from "../../Images/close-no-border.svg";


function ParkersDetail() {
  var dataAlertSingleButtonModel: AlertSingleButtonModel
  dataAlertSingleButtonModel = {}
  var dataAlertDualButtonModel: AlertDualButtonModel
  dataAlertDualButtonModel = {}
  const state = useSelector((state: any) => state);
  const SessionDetails = state.user.SessionDetails;
  const MultiSessionDetails = state.user.MultiSessionDetails;
  // const dispatch = useDispatch();
  const api = new API();
  const navigate = useNavigate();
  const Location: any = useLocation()
  const [listParkersDetails] = useState(Location.state.listParkerDetail);
  const [licensePlateNumber, setLicensePlateNumber] = useState("");
  const [parkerHistory, setParkerHistory] = useState([])
  const timeout = 1000 * 60 * 15
  const [isIdle, setIsIdle] = useState(false)
  const handleOnActive = () => setIsIdle(false)
  const handleOnIdle = () => setIsIdle(true)
  const isRunned = useRef(false);
  const [alertSingleData, setAlertSingleData] = useState(dataAlertSingleButtonModel);
  const [alertDualData, setAlertDualData] = useState(dataAlertDualButtonModel);


  useIdleTimer({
    timeout,
    onActive: handleOnActive,
    onIdle: handleOnIdle
  })

  useEffect(() => {
    !isRunned.current &&
      getParkerHistory()
    return () => {
      isRunned.current = true;
    };
  }, [licensePlateNumber]);


  useEffect(() => {
    const set = setInterval(() => {
      if (isIdle) {
        // console.log("isIdle", isIdle)
        setTimeout(() => {
          clearInterval(set)
                    setAlertSingleData({
                      showAlert: true, alertData: {
                        startImg: "",
                        title: "Session-Out",
                        subTitle: "Your session has expired due to 15 minutes of inactivity.",
                        okayButtonName: "Ok",
                        actionName: "Session-Out"
                      }
                    })
        }, 1000);
      }
      else {
        // console.log("isIdle", isIdle)
      }
    }, 1000);
    return () => clearInterval(set)
  }, [isIdle])

  const mount = () => {
    // console.log('mounted')
    if (!localStorage.getItem('access_token') || (!SessionDetails.propertyGuid) ||
      (MultiSessionDetails.length > 0 && MultiSessionDetails.findIndex((x: any) =>!x.selectedZoneGuid) !== -1)) {
      navigate("/")
    }

    return () => {
      // console.log('unmounted')
      // ...
    }
  }
  useEffect(mount, [])

  useEffect(() => {
    setLicensePlateNumber(listParkersDetails.licensePlate);
  }, []);
  const handleDualAButton = (actionName: string) => {
    setAlertDualData({
      showAlert: false
    })
    if(actionName === "End-Session"){
      localStorage.removeItem('access_token');
      navigate('/');
    }
  }

  const handleDualBButton = (actionName: string) => {
    setAlertDualData({
      showAlert: false
    })
    if(actionName === "End-Session"){
      getEndSession();
    }
  }


  const handleAlertOkayButton = (actionName: string) => {
    if (actionName === "ERROR-400") {
      setAlertSingleData({
        showAlert: false
      })
    }
    if (actionName === "Session-Out") {
      setAlertSingleData({
        showAlert: false
      })
      getEndSession();
    }

  }

  const handleClose = () => {
    setAlertDualData({
      showAlert: false,
    })
  };

  const ShowDualAlert = () => {
    var Title = (alertDualData.alertData?.title) ? alertDualData.alertData?.title : ""
    var SubTitle = (alertDualData.alertData?.subTitle) ? alertDualData.alertData?.subTitle : ""
    var DualAButton = (alertDualData.alertData?.dualAButton) ? alertDualData.alertData?.dualAButton : ""
    var DualBButton = (alertDualData.alertData?.dualBButton) ? alertDualData.alertData?.dualBButton : ""
    var startImg = (alertDualData.alertData?.startImg) ? alertDualData.alertData?.startImg : ""
    var ActionName = (alertDualData.alertData?.actionName) ? alertDualData.alertData?.actionName : ""
    var closeButton = alertDualData.alertData?.closeButton;
    return (
        alertDualData.showAlert && (
            <AlertView
                title={Title}
                subTitle={SubTitle}
                startImg={startImg}
                closeButton={closeButton}
                dualAButton={DualAButton}
                dualBButton={DualBButton}
                handleDualAButton={() => {
                  handleDualAButton(ActionName)
                }}
                handleDualBButton={() => {
                  // console.log("called");
                  handleDualBButton(ActionName)
                }}
                handleClose={handleClose}
            />
        )
    )
}

  const getEndSession = async () => {
    await api.get_end_session(String(localStorage.getItem('end_session_guid')));
    // if (endSessionRes === 200) {
      localStorage.removeItem('end_session_guid');
      navigate("/FindSpot");
    // }
  };

  const getParkerHistory = async () => {
    const { result: getHistoryRes } = await api.get_history(listParkersDetails.licensePlate);
    if (Array.isArray(getHistoryRes)) {
      setParkerHistory(getHistoryRes);
    }
  };

  const ShowokayAlert = () => {
    var startImg = (alertSingleData.alertData?.startImg) ? alertSingleData.alertData?.startImg : ""
    var Title = (alertSingleData.alertData?.title) ? alertSingleData.alertData?.title : ""
    var SubTitle = (alertSingleData.alertData?.subTitle) ? alertSingleData.alertData?.subTitle : ""
    var OkayButtonName = (alertSingleData.alertData?.okayButtonName) ? alertSingleData.alertData?.okayButtonName : ""
    var ActionName = (alertSingleData.alertData?.actionName) ? alertSingleData.alertData?.actionName : ""
    return (
      alertSingleData.showAlert && (
        <AlertView
          startImg={startImg}
          title={Title}
          subTitle={SubTitle}
          singleOkButton={OkayButtonName}
          handleOkay={() => {
            handleAlertOkayButton(ActionName)
          }} />
      )
    )

  }
  const listItems = parkerHistory.map((lp, index) =>
        <div className="CenterView" >
            <div style={{ 'width': '100%' }} key={`parker-history-${index}`}>
                <div style={{  'borderTop': '2px solid #C2C6CA', 'height': '41px','width': '100%' }} className="RowViewSpaceBetween" >
                    <div style={{ 'width': '100%', 'justifyContent': 'center',}}
                      >
                        <h1 className="truncate" style={{'color':'#6B7278'}} >{`${moment(lp["startTimeLocal"]).format("MMMM")} ${moment(lp["startTimeLocal"]).format("DD")} ${moment(lp["startTimeLocal"]).format("YYYY")}`}</h1>
                    </div>
                    <div style={{ 'width': '100%', 'justifyContent': 'center' }}
                         >
                        <h1 className="truncate" style={{'color':'#6B7278'}}>{`${moment(lp["startTimeLocal"]).format("hh:mm:a")}.`}</h1>
                    </div>
                    <div style={{ 'width': '100%', 'justifyContent': 'center' }}

                        >
                       <h1 className="truncate" style={{'color':'#6B7278'}}>{lp["spotName"]}</h1>
                    </div>
                </div>
            </div>
        </div>
    );


  return (
    <div style={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }}>
      <div className="BaseContainer">
      {
        ShowDualAlert()
      }
      {
        ShowokayAlert()
      }
        <div className="RowView" style={{ 'justifyContent': 'space-between', width: "100%", marginTop: '20px' }}>
          <div className="RowView" onClick={() => {
            navigate(-1)
          }}>
            <Button><img src={Back} alt="" style={{ width: 18, height: 18 }}
              onClick={() => {
                //   navigate(-1)
              }}></img>Back</Button>
          </div>
          <div>
            <Button onClick={() => {
              setAlertDualData({
                showAlert: true,
                alertData: {
                  title: "Enforcement Check Complete",
                  subTitle: `Parking enforcement check for ${SessionDetails.propertyName} - ${MultiSessionDetails.length > 0 ? MultiSessionDetails.map((x: any) => x["selectedZone"]).join(",") : ""} has been successfully completed. To proceed, please choose one of the following options:`,
                  startImg: "",
                  dualAButton: "End Session",
                  dualBButton: "New Session",
                  actionName: "End-Session",
                  closeButton: CloseIcon
                }
              })

              // getEndSession();

            }}> End Session</Button>
          </div>

        </div>
        <div>
          <h1 style={{ 'fontFamily': 'Manrope', 'fontWeight': '800', 'fontSize': '17px' }}>Parker Information</h1>
        </div>
        <div className="RowView" style={{ alignItems: 'flex-start', width: '80%' }}>
          <h1 style={{ 'fontFamily': 'Manrope', 'fontWeight': '800', 'fontSize': '17px' }}>License Plate:</h1>
          <h1 style={{ 'fontFamily': 'Manrope', 'fontWeight': '400', 'fontSize': '17px', 'marginLeft': '10px' }}>{licensePlateNumber}</h1>
        </div>
        <div style={{ 'width': '85%', 'height': '2px', 'backgroundColor': '#DADDDF' }}>

        </div>
        <div style={{ 'width': '85%', 'marginTop':'10px'}}>
          {
            parkerHistory.length > 0 && (
              <div style={{}}>
              <div style={{ 'width': '100%', height: '41px' }} className="RowViewSpaceBetween">
                <div style={{ 'width': '100%',}} >
                  <h1 className="SubTitle" >Date</h1>
                </div>
                <div style={{ 'width': '100%', 'backgroundColor': 'white', marginLeft: '10px', justifyContent: 'center' }} >
                  <h1 className="SubTitle" style={{  }}>Time</h1>
                </div>
                <div style={{ 'width': '100%', 'backgroundColor': 'white', justifyContent: 'center' }} >
                  <h1 className="SubTitle" style={{}}>Spot Number</h1>
                </div>

              </div>
              <div style={{  }} className="ListOfParkers-min-hight">
                                    <div style ={{'width':'100%'}}>{listItems}</div>
                                    <div style={{ 'width': '100%', 'height': '2px', 'backgroundColor': '#DADDDF' , 'marginTop':"10px"}}/>
                                </div>

              </div>

            ) || (<h2 style={{ 'fontFamily': 'Manrope', 'fontWeight': '500', 'fontSize': '12px', 'color': '#6B7278' }}>No history of illegal parks for this driver.</h2>)
          }

        </div>

      </div>
    </div>
  )

}
export default ParkersDetail
