import React, { useEffect, useState, useRef } from "react";
import './ReportPopUpView.css'
import { Button, TextField } from "@mui/material";
import CloseIcon from '../../Images/close-no-border.svg'
import DropDown from '../../Images/dropdown.svg';
import DropDown2 from '../../Images/dropdown_2.svg';

function ReportPopUpView(_props: any) {
    const ref = useRef<HTMLInputElement>(null);
    const [title, setTitle] = useState("");
    const [subTitle, setSubTitle] = useState("");
    const [spotNumberTF, setSpotNumberTF] = useState(_props.spotName);
    const [licensePlateTF, setLicensePlateTF] = useState("");
    const [, setLicensePlateEditable] = useState(false);
    const [makeTF, setMakeTF] = useState("");
    const [modelTF, setModelTF] = useState("");
    const [colorTF, setColorTF] = useState("");
    const [, setReasonTF] = useState("");
    const [, setShowReportMenu] = useState(false);
    const [height, setHeight] = useState(0);
    const [reasonId, setReasonId] = useState(0);
    const [showSpotTF, setShowSportTF] = useState(true);
    const [reasonForReporting, setReasonForReporting] = useState("Select reason for reporting");
    const [reasonForReportingLabel, setReasonForReportingLabel] = useState("Select reason for reporting");
    const [isDropDown, setDropDown] = useState(false);
    const [reasonList, setReasonList] = useState([]);




    useEffect(() => {
        if (ref.current != null) {
            setHeight(ref.current.clientHeight)
        }
        setTitle(_props.title);
        setSubTitle(_props.subTitle ? _props.subTitle : "");
        setShowReportMenu(_props.showReportMenu ? _props.showReportMenu : false);
        setShowSportTF(_props.showSpotTF);
    })
    useEffect(() => {
        setLicensePlateEditable(_props.licensePlateEditable)
        setReasonList(typeof _props.reasonList == 'undefined'? []:_props.reasonList);
        setLicensePlateTF(_props.licensePlate)
    },[])

    const reasonListItems = reasonList.map((reason, index) =>
        <div className={index === 0 ? "dropDown-label-1" : index === reasonList.length - 1 ? "dropDown-label-3" : "dropDown-label-2"} onClick={() => { { setReasonForReporting(reason["reasonName"]) } { setDropDown(!isDropDown) } { setReasonForReportingLabel("Reason for Reporting") } { setReasonId(reason["reasonId"]) } }} key={`reason2-${index}`}>
            <label style={{ touchAction: 'auto' }}>
                {reason["reasonName"]}
            </label>
        </div>

    );
    return (
        <div className="popUpBasicContrainer">
            {/* <div style={{ 'minHeight': `calc(100vh - ${height}px)`,'backgroundColor': 'yellow' }} className="popUp-min-hight"> */}
            <div style={{ 'minHeight': `calc(100vh - ${height}px)`, 'zIndex': -1 }} >
            </div>
            <div style={{ 'overflowY': 'scroll', 'zIndex': 3 }}>
                <div className="CenterView">
                    <div style={{}} ref={ref} className="pop-up">
                        <div style={{ width: '100%', marginTop: '20px' }} className="CenterView">
                            <div style={{ marginTop: '20px' }} className="RightView">
                                <div >
                                    <img src={CloseIcon} className="close-icon" alt=""
                                        onClick={() => {
                                            _props.handelClose()
                                        }}
                                    />
                                </div>
                            </div>

                            <div style={{}} className="CenterView">
                                <small className="pop-up-title">{title}</small>
                                <div style={{ width: '90%', 'textAlign': 'center', 'margin': '20px' }} className="CenterView">
                                    <small className="pop-up-sub-title">{subTitle}</small>
                                </div>

                                <div style={{ marginTop: '0px' }} className="TF-container-view">
                                    <TextField fullWidth className="MatrialTextFieldFull outlined-basic" size="medium" label="Spot Number" variant="outlined" color="primary" autoComplete="off" value={spotNumberTF}
                                        inputProps={{ maxLength: 30, readOnly: showSpotTF }}
                                        onChange={(e: any) => {setSpotNumberTF(e.target.value)}}
                                        onFocus={() => {setDropDown(false)}}
                                    />
                                </div>

                                <div className="TF-container-view ">

                                    <TextField fullWidth className="MatrialTextFieldFull outlined-basic" size="medium" label="License Plate" variant="outlined" color="primary" autoComplete="off" value={licensePlateTF}
                                        inputProps={{ maxLength: 9}}
                                        onChange={(e: any) => {
                                            let firstLetter = e.target.value.charAt(0);
                                            e.target.value = firstLetter.toUpperCase() + e.target.value.slice(1);
                                            if(e.target.value.length <= 9){
                                            setLicensePlateTF(e.target.value)
                                            }
                                        }}
                                        onFocus={() => {setDropDown(false)}}
                                    />
                                </div>
                                <div className="TF-container-view ">
                                    <TextField fullWidth className="MatrialTextFieldFull outlined-basic" size="medium" label="Make" variant="outlined" color="primary" autoComplete="off" value={makeTF}
                                        inputProps={{ maxLength: 20 }}
                                        onChange={(e: any) => {
                                            let firstLetter = e.target.value.charAt(0);
                                            e.target.value = firstLetter.toUpperCase() + e.target.value.slice(1);
                                            if(e.target.value.length <= 20){
                                            setMakeTF(e.target.value)
                                            }
                                        }}
                                        onFocus={() => {setDropDown(false)}}
                                />
                                </div>
                                <div className="TF-container-view ">
                                    <TextField fullWidth className="MatrialTextFieldFull outlined-basic" size="medium" label="Model" variant="outlined" color="primary" autoComplete="off" value={modelTF}
                                        inputProps={{ maxLength: 20 }}
                                        onChange={(e: any) => {
                                            let firstLetter = e.target.value.charAt(0);
                                            e.target.value = firstLetter.toUpperCase() + e.target.value.slice(1);
                                            if(e.target.value.length <= 20){
                                                setModelTF(e.target.value)
                                            }
                                        }}
                                        onFocus={() => {setDropDown(false)}}
                                />
                                </div>
                                <div className="TF-container-view ">

                                    <TextField fullWidth className="MatrialTextFieldFull outlined-basic" size="medium" label="Colour" variant="outlined" color="primary" autoComplete="off"
                                        inputProps={{ maxLength: 20 }}
                                        onChange={(e: any) => {
                                            let firstLetter = e.target.value.charAt(0);
                                            e.target.value = firstLetter.toUpperCase() + e.target.value.slice(1);
                                            if(e.target.value.length <= 20){
                                                setColorTF(e.target.value)
                                            }
                                        }}
                                        onFocus={() => {setDropDown(false)}}
                                />
                                </div>
                                {/* <div style={{ 'display': 'flex', 'flexDirection': 'column', 'alignItems': 'center', 'width': '100%' }}>
                                    <div style={{ marginBottom: '0px' }} className="TF-container-view ">
                                        <TextField fullWidth className="MatrialTextFieldFull" style={{ marginBottom: '0px' }} id="outlined-basic" size="medium" label="Reason for Reporting" variant="outlined" color="primary" autoComplete="off" value={colorTF}
                                            inputProps={{ maxLength: 30 }}
                                            onChange={(e: any) => {
                                                setColorTF(e.target.value)
                                            }}
                                            onFocus={(e) => {

                                                // setFooterPosition("relative")
                                            }}
                                            onBlur={(e) => {
                                                // setFooterPosition("fixed")

                                            }}

                                        />
                                    </div>
                                    <div style={{ 'backgroundColor': 'white', 'width': '90%', borderRadius: '10px', marginTop: '-10px' }}>{listItems}</div>
                                    <div>

                                    </div>
                                </div> */}
                                {/* <div className="TF-container-view ">

                                    <TextField fullWidth className="MatrialTextFieldFull" id="outlined-basic" size="medium" label="Color" variant="outlined" color="primary" autoComplete="off"
                                        inputProps={{ maxLength: 30 }}
                                        onChange={(e: any) => {
                                            setColorTF(e.target.value)
                                        }}
                                        onFocus={(e) => {

                                            // setFooterPosition("relative")
                                        }}
                                        onBlur={(e) => {
                                            // setFooterPosition("fixed")

                                        }}

                                    />
                                </div> */}
                                {
                                    showSpotTF && <div className="TF-container-view ">
                                        <div className="dropdown-container">
                                            <div className="dropDown-center">
                                                <img src={isDropDown ? DropDown2 : DropDown} alt=""
                                                    onClick={() => {
                                                        setDropDown(!isDropDown)
                                                    }}
                                                />
                                            </div>
                                            <TextField sx={reasonForReporting.includes("Select") ? { input: { color: '#6B7278' } } : { input: { color: '#111012' } }} fullWidth className="MatrialTextFieldFull outlined-basic" size="medium" label={reasonForReportingLabel} variant="outlined" color="primary" autoComplete="off" value={reasonForReporting}
                                                inputProps={{ readOnly: true }}
                                                onChange={(e: any) => {
                                                    setReasonTF(e.target.value)

                                                }}
                                                onClick={() => {
                                                    setReasonForReportingLabel("Reason for Reporting")
                                                    setDropDown(!isDropDown)
                                                }}
                                            />

                                        </div>
                                    </div>
                                }
                                {
                                    isDropDown && <div className="TF-container-view ">
                                        <div>
                                            {reasonListItems}
                                        </div>

                                        {/* <Select
                                        fullWidth
                                        defaultValue="Illegal Parker"
                                        className="InputLabel"
                                        value={reasonForReporting}
                                        placeholder="Select reason for reporting"
                                        onChange={(e) => {
                                            setReasonForReporting(e.target.value)
                                            // setDuration("")
                                        }}
                                        MenuProps={{
                                            sx: {
                                                "&& .Mui-selected": {
                                                    borderBottom: "1px solid #F8F8F8",
                                                    backgroundColor: 'white'
                                                },
                                            }
                                        }}
                                    >
                                        <MenuItem defaultValue={"Illegal Parker"} value={"Illegal Parker"}>Illegal Parker</MenuItem>
                                        <MenuItem value={"Parked in wrong spot"}>Parked in wrong spot</MenuItem>
                                        <MenuItem value={"Other"}>Other</MenuItem>
                                    </Select> */}
                                    </div>
                                }

                                <div style={{ 'marginTop': "20px", 'width': '90%', 'paddingBottom': '60px' }}>
                                    <Button style={licensePlateTF.length >= 3 && (showSpotTF ? reasonId !== 0 : licensePlateTF) ? { width: '100%', height: '48px', backgroundColor: '#00AF85', fontSize: "15px", fontFamily: 'Manrope', fontWeight: '700', borderRadius: '12px' } : { width: '100%', height: '48px', backgroundColor: '#8A959E', fontSize: "15px", fontFamily: 'Manrope', fontWeight: '700', borderRadius: '12px' }} variant="contained"
                                        onClick={() => {                                            

                                            if (showSpotTF) {
                                                if (licensePlateTF.length >= 3  && reasonForReporting.length >= 3 && reasonId !== 0) {
                                                    _props.handelOkButton(licensePlateTF, makeTF, modelTF, colorTF, reasonId)

                                                }
                                            }
                                            else {
                                                if (licensePlateTF.length >= 3) {
                                                    _props.handelOkButton(licensePlateTF, makeTF, modelTF, colorTF)

                                                }
                                            }
                                        }
                                        }
                                    >
                                        Submit Report
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
} export default ReportPopUpView

