
export default interface dispatchModel {
   type:string,
   payload:any
 }
 
export const addSession = (data:any) => {
    return (dispatch:any) => {
        dispatch({
            type: "addSessionDetails",
            payload: data
        });
    }
};

export const addMultiSession = (data:any) => {
    return (dispatch:any) => {
        dispatch({
            type: "addMultiSessionDetails",
            payload: data
        });
    }
};
