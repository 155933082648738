import React from "react";
import "./HeaderView.css";
import { Button } from "@mui/material";
import BackImg from '../../Images/Back.svg'

function HeaderView(_props: any) {

    return (
        <div>
            {/* <div style={{}} className="coloum-container"> */}
                <div style={{ alignItems: 'center'}} className="row-container">
                    {
                        _props.showBackBT && (
                            <Button>
                                <img src={BackImg} className="Back-Icon" alt=""
                                    onClick={() => {

                                    }}
                                />
                            </Button>
                        )
                    }
                    <div className="title-container">
                        <h2 className="title-header">
                        {_props.title}
                        </h2>
                        {_props.subtitle && <p>{_props.subtitle}</p>}
                    </div>
                </div>
            {/* </div> */}
        </div>
    )
}

// interface HeaderViewProps {

// }
// interface HeaderViewState {

// }
// class HeaderView extends React.Component<HeaderViewProps, HeaderViewState> {

//   constructor(props: HeaderViewProps) {
//     super(props);

//   }

//   render() {
//     return (
//       <div className="header-fixed-hight">
//         <div style={{}} className="coloum-container">
//           <div style={{ height: '70px', justifyContent: 'flex-start', alignItems: 'center' }} className="row-container">
//             <Button>
//                 <img src={BackImg} className="Back-Icon"
//                   onClick={() => {

//                   }}
//                 />
//               </Button>
//               <div className="title-container">
//               <h2 className="title-header">
//                 Log In
//               </h2>
//               </div>
//             </div>
//         </div>
//       </div>
//     );
//   }
// }

export default HeaderView;
