import React, { useEffect, useState, useRef, useCallback } from "react";
import './ListOfParkers.css';
import { Button, TextField, InputAdornment } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { bindActionCreators } from 'redux';
import { actionCreators } from "../../state/index";
import LocArrowsvg from '../../Images/SearchLens.svg';
import CloseSVG from '../../Images/close.svg';
import API from "../../Services/API";
import cancelGray from '../../Images/cancel.svg';
import cancelRed from '../../Images/cancel-red.svg';
import cancelOrange from '../../Images/cancel-orange.svg';
import SpinnerView from "../../Components/SpinnerView/SpinnerView";
import PopUpView from "../../Components/PopUpView/PopUpView";
import ReportPopUpView from "../../Components/PopUpView/ReportPopUpView";
import AlertView from "../../Components/AlertView/AlertView";
import { useNavigate, useLocation } from 'react-router-dom';
import AlertSingleButtonModel from "../../Model/AlertSingleButtonModel";
import AlertDualButtonModel from "../../Model/AlertDualButtonModel";
import moment from 'moment';
import { useIdleTimer } from 'react-idle-timer';
import { ReportQueue } from "./ReportQueue";
import CloseIcon from '../../Images/close-no-border.svg';

function ListOfParkers() {
    var dataAlertSingleButtonModel: AlertSingleButtonModel = {};
    var dataAlertDualButtonModel: AlertDualButtonModel = {};
    const [alertSingleData, setAlertSingleData] = useState(dataAlertSingleButtonModel);
    const [alertDualData, setAlertDualData] = useState(dataAlertDualButtonModel);
    const state = useSelector((state: any) => state);
    const MultiSessionDetails = state.user.MultiSessionDetails;
    const SessionDetails = state.user.SessionDetails;
    const dispatch = useDispatch();
    const { addMultiSession, addSession } = bindActionCreators(actionCreators, dispatch);
    const [SearchText, setSearchText] = useState("");
    const api = new API();
    const [parkerList, setParkerList] = useState([]);
    const [spinner, setSpinner] = useState(false);
    const [clearedLotPopUpView, setClearedLotPopUpView] = useState(false);
    const [enforcementCheckCompletePopUpView, setEnforcementCheckCompletePopUpView] = useState(false);
    const [reportParkerPopUpView, setReportParkerPopUpView] = useState(false);
    // const [miniReportParkerPopUpView, setMiniReportParkerPopUpView] = useState(false);
    const [spotGuid, setSpotGuid] = useState("");
    const [spotName, setSpotName] = useState("");
    const [reportParkerSubmitPopUpView, setReportParkerSubmitPopUpView] = useState(false);
    const [reportedCount, setReportedCount] = useState(0);
    const [, setDynamicHight] = useState('79vh');
    const navigate = useNavigate();
    const listInnerRef = React.useRef<HTMLInputElement>(null);
    const [listReachedBottom, setListReachedBottom] = useState(false);
    const [showParkerNotInSystem, setShowParkerNotInSystem] = useState(false);
    const [isParkers, setIsParkers] = useState(false);
    const [reasonList, setReasonList] = useState([]);
    const timeout = 1000 * 60 * 15;
    const [isIdle, setIsIdle] = useState(false);
    const handleOnActive = () => setIsIdle(false);
    const handleOnIdle = () => setIsIdle(true);
    const [tempReportedArr, setTempReportedArr] = useState(SessionDetails.tempReportedArr ? SessionDetails.tempReportedArr : [] as object[]);
    const isParkerNotInSystem = useRef(false);
    const isLicensePlateEditable = useRef(false);
    const [flagToBack,setFlagToBack] = useState(false);
    const textInput = useRef(null);
    const location = useLocation();

    useIdleTimer({
        timeout,
        onActive: handleOnActive,
        onIdle: handleOnIdle
    });

    useEffect(() => {
        const set = setInterval(() => {
            if (isIdle) {
                setTimeout(() => {
                    clearInterval(set)
                    setAlertSingleData({
                        showAlert: true, alertData: {
                            startImg: "",
                            title: "Session-Out",
                            subTitle: "Your session has expired due to 15 minutes of inactivity.",
                            okayButtonName: "Ok",
                            actionName: "Session-Out"
                        }
                    });
                }, 1000);
            }
        }, 1000);
        return () => {
            clearInterval(set);
        }
    }, [isIdle]);

    const [finishStatus, setfinishStatus] = useState(false);

    const onBackButtonEvent = (e: { preventDefault: () => void; }) => {
        if (!flagToBack) {
            e.preventDefault();
            if (!finishStatus) {
                setAlertDualData({
                    showAlert: true,
                    alertData: {
                        title: "Enforcement Check Complete",
                        subTitle: `Parking enforcement check for ${SessionDetails.propertyName} - ${MultiSessionDetails.length > 0 ? MultiSessionDetails.map((x: any) => x["selectedZone"]).join(",") : ""} has been successfully completed. To proceed, please choose one of the following options:`,
                        startImg: "",
                        dualAButton: "End Session",
                        dualBButton: "New Session",
                        actionName: "End-Session",
                        closeButton: CloseIcon
                    }
                });
            }
        } else {
            navigate(-1);
        }
    };

    useEffect(() => {
        window.history.pushState(null, "", window.location.pathname);
        window.addEventListener('popstate', onBackButtonEvent);
        return () => {
            window.removeEventListener('popstate', onBackButtonEvent);
        };
    }, [flagToBack]);

    useEffect(() => {
        if (!localStorage.getItem('access_token') || (!SessionDetails.propertyGuid) || (MultiSessionDetails.length > 0 && MultiSessionDetails.findIndex((x: any) =>!x.selectedZoneGuid) !== -1)) {
            navigate("/");
        }
    }, []);

    useEffect(() => {
        getListOfParker();
    }, [SearchText]);

    useEffect(() => {
        window.onbeforeunload = function () {
            return true;
        };
        return () => {
            window.onbeforeunload = null;
        };
    });

    const iSScrollReachBottonlevel1 = async (array: []) => {
        if (listInnerRef.current) {
            const { clientHeight } = listInnerRef.current;
            if (array.length <= clientHeight / 40) {
                setListReachedBottom(true);
            } else {
                setListReachedBottom(false);
            }
        }
    };

    const getReportParkerNotInSystem = async (licensePlate: any, makeTF: any, modelTF: any, colorTF: any) => {
        setSpinner(true);
        const PostData = {
            LicensePlate: licensePlate,
            Make: makeTF,
            Model: modelTF,
            Colour: colorTF,
            PropertyGuid: SessionDetails.propertyGuid
        };
        const { result: reportedCount, offline, error } = await api.get_report_scramble_parker(PostData);
        if (offline) {
            ReportQueue.set(`${SessionDetails.propertyGuid}_${licensePlate}`, {endpoint: "report_scramble_parker", body: PostData});
        }
        setSpinner(false);
        if (typeof reportedCount === 'number') {
            setReportedCount(reportedCount);
        }
        if (!offline) {
            if (error) {
                setAlertSingleData({
                    showAlert: true,
                    alertData: {
                        startImg: "",
                        title: "GrydPark",
                        subTitle: "Something went wrong. Please try restarting the app.",
                        okayButtonName: "Ok",
                        actionName: "ERROR-400"
                    }
                });
            } else {
                setReportParkerPopUpView(false);
                setReportParkerSubmitPopUpView(true);
            }
        } else {
            setReportParkerPopUpView(false);
            setAlertSingleData({
                showAlert: true,
                alertData: {
                    startImg: "",
                    title: "GrydPark",
                    subTitle: "Report queued due to offline mode. Connect to the Internet to make them effective.",
                    okayButtonName: "Ok",
                    actionName: "ERROR-400"
                }
            });
        }
    };

    const onScroll = () => {
        if (listInnerRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
            if (scrollTop + clientHeight === scrollHeight) {
                setListReachedBottom(true);
            }
        }
    };

    const onClickParker = async (lp: any) => {
        if (lp.licensePlate) {
            if (!navigator.onLine) {
                setAlertSingleData({
                    showAlert: true,
                    alertData: {
                        startImg: "",
                        title: "GrydPark",
                        subTitle: "You need an internet connection to perform this action.",
                        okayButtonName: "Ok",
                        actionName: "ERROR-400"
                    }
                });
                return;
            }
            navigate('/ParkerDetail', {
                state: {
                    listParkerDetail: lp
                }
            });
        }
    };

    const getEndSession = async () => {
        setSpinner(true);
        await api.get_end_session(String(localStorage.getItem('end_session_guid')));
        // if (endSessionRes === 200) {
            localStorage.removeItem('end_session_guid');
            navigate("/FindSpot");
        // }
        setSpinner(false);
    };

    const getReportParker = async (licensePlate: any, makeTF: any, modelTF: any, colorTF: any, reasonId: any) => {
        setSpinner(true);
        const PostData = {
            spotGuid: spotGuid,
            spotName: spotName,
            LicensePlate: licensePlate,
            Make: makeTF,
            Model: modelTF,
            Colour: colorTF,
            ReasonId: reasonId,
            propertyGuid: SessionDetails.propertyGuid
        };
        const { result: reportedCount, offline, error } = await api.get_report_parker(PostData);
        if (offline) {
            ReportQueue.set(`${SessionDetails.propertyGuid}_${spotGuid}`, {endpoint: "report_parker", body: PostData});
            setSpinner(false);
        }
        setSpinner(false);
        let copyArr = tempReportedArr;
        copyArr.push({spot: spotName, offline});
        const SessionDetailstemp = {
            curLatitude: SessionDetails.curLatitude,
            curLongitude: SessionDetails.curLongitude,
            propertyGuid: SessionDetails.propertyGuid,
            propertyName: SessionDetails.propertyName,
            sessionGuid: SessionDetails.sessionGuid,
            tempReportedArr: copyArr,
        };
        addSession(SessionDetailstemp);
        const sessionDetails = MultiSessionDetails.map((sessionDetail: any) => {
            return {
                curLatitude: sessionDetail.curLatitude,
                curLongitude: sessionDetail.curLongitude,
                propertyGuid: sessionDetail.propertyGuid,
                propertyName: sessionDetail.propertyName,
                selectedZone: sessionDetail.selectedZone,
                selectedZoneGuid: sessionDetail.selectedZoneGuid,
                sessionGuid: sessionDetail.sessionGuid,
                tempReportedArr: copyArr
            }
        });
        addMultiSession(sessionDetails);
        setTempReportedArr(SessionDetails.tempReportedArr);
        if (typeof reportedCount === "number") {
            setReportedCount(reportedCount);
        }
        if (!offline) {
            if (error) {
                setAlertSingleData({
                    showAlert: true,
                    alertData: {
                        startImg: "",
                        title: "GrydPark",
                        subTitle: "Something went wrong. Please try restarting the app.",
                        okayButtonName: "Ok",
                        actionName: "ERROR-400"
                    }
                });
            } else {
                setReportParkerPopUpView(false);
                setReportParkerSubmitPopUpView(true);
            }
        }  else {
            setReportParkerPopUpView(false);
            setAlertSingleData({
                showAlert: true,
                alertData: {
                    startImg: "",
                    title: "GrydPark",
                    subTitle: "Report queued due to offline mode. Connect to the Internet to make them effective.",
                    okayButtonName: "Ok",
                    actionName: "ERROR-400"
                }
            });
        }
    };

    const getReasonList = async () => {
        let reasonList: [] = [];
        setSpinner(true);
        if (navigator.onLine) {
            const { result, offline, error } = await api.get_reason_list();
            if (offline || error || !Array.isArray(result)) {
                try {
                    reasonList = JSON.parse(localStorage.getItem('reasons')!);
                } catch (_e) {}
            } else {
                reasonList = result;
            }
        } else {
            try {
                reasonList = JSON.parse(localStorage.getItem('reasons')!);
            } catch (_e) {}
        }
        setSpinner(false);
        setReasonList(reasonList);
        isParkerNotInSystem.current = true;
        isLicensePlateEditable.current = false;
        setReportParkerPopUpView(true);
    };

    const getListOfParker = useCallback(async () => {
        const postDetails = {
            propertyGuid: SessionDetails.propertyGuid,
            ZoneGuid: MultiSessionDetails.map((x: any) => x["selectedZoneGuid"])
        };
        setSpinner(true);
        const { result: freshListOfParkers, offline, error } = await api.get_list_of_parkers(postDetails);
        setSpinner(false);
        setIsParkers(false);
        let listOfParkers: [] = [];
        if (Array.isArray(freshListOfParkers) && !error) {
            // Use fresh data
            console.debug("Using fresh parkers data");
            listOfParkers = freshListOfParkers;
            localStorage.setItem('parkers', JSON.stringify(listOfParkers));
            iSScrollReachBottonlevel1(listOfParkers);
        } else if ((!navigator.onLine || offline) && localStorage.getItem('parkers')) {
            // Use stale data
            try {
                console.debug("Using stale parkers data");
                listOfParkers = JSON.parse(localStorage.getItem('parkers')!);
            } catch (_e) {
            }
        } else {
            console.debug("Using empty parkers data");
        }
        if (Array.isArray(listOfParkers)) {
            const cleanText = (text: string) => text.replace(/[^a-z0-9]/gi, '').toLowerCase();

            if (SearchText === "") {
                setParkerList(listOfParkers);
                setShowParkerNotInSystem(false);
                if (listOfParkers.length > 0) {
                    setIsParkers(true);
                }
            } else {
                const cleanedSearchText = cleanText(SearchText);
                var foundValue = listOfParkers.filter((item: any) => {
                    var a: string = cleanText(item["spotName"]);
                    var b: string = cleanText(item["licensePlate"]);
                    return a.includes(cleanedSearchText) || b.includes(cleanedSearchText);
                });
                if (foundValue.length > 0) {
                    setIsParkers(true);
                }
                setParkerList(foundValue)
                if (foundValue.length === 0) {
                    setShowParkerNotInSystem(true);
                } else {
                    setShowParkerNotInSystem(false);
                }
            }
        }
    }, [MultiSessionDetails, SearchText, SessionDetails.propertyGuid, api]);

    const handleAlertOkayButton = (actionName: string) => {
        if (actionName === "Scan-Recorded") {
            setAlertSingleData({ showAlert: false });
            getEndSession();
        } else if (actionName === "Session-Out") {
            setAlertSingleData({ showAlert: false });
            getEndSession();
        } else if (actionName === "ERROR-400") {
            setAlertSingleData({ showAlert: false });
        }
        isParkerNotInSystem.current = true;
        isLicensePlateEditable.current = false;
    };

    const handleDualAButton = (actionName: string) => {
        if (actionName === "Unfinished-Scan") {
            setAlertDualData({ showAlert: false });
            setAlertDualData({
                showAlert: true, alertData: {
                    title: "Save Session",
                    subTitle: "Do you want this scanning session recorded? ",
                    dualAButton: "Yes",
                    dualBButton: "No",
                    actionName: "Save-Session"
                }
            });
        } else if (actionName === "Save-Session") {
            setAlertDualData({ showAlert: false });
            setAlertSingleData({
                showAlert: true, alertData: {
                    startImg: require('../../Images/tick.png'),
                    title: "Scan Recorded",
                    subTitle: "Your scan has been recorded and your session has ended.",
                    okayButtonName: "Ok",
                    actionName: "Scan-Recorded"
                }
            });
        } else if (actionName === "Go-Back") {
            setFlagToBack(true);
            navigate(-1)
        } else if (actionName === "End-Session") {
            setfinishStatus(true);
            localStorage.removeItem('access_token');
            navigate('/');
        }
    };

    const handleDualBButton = (actionName: string) => {
        setAlertDualData({ showAlert: false });
        if (actionName === "Unfinished-Scan") {
            setAlertDualData({ showAlert: false });
        } else if (actionName === "Save-Session") {
            setAlertDualData({ showAlert: false });
            setfinishStatus(true);
            getEndSession();
        } else if (actionName === "Go-Back") {
            setAlertDualData({ showAlert: false });
        } else if (actionName === "End-Session") {
            setfinishStatus(true);
            getEndSession();
        }
    };

    const ShowokayAlert = () => {
        var startImg = (alertSingleData.alertData?.startImg) ? alertSingleData.alertData?.startImg : "";
        var Title = (alertSingleData.alertData?.title) ? alertSingleData.alertData?.title : "";
        var SubTitle = (alertSingleData.alertData?.subTitle) ? alertSingleData.alertData?.subTitle : "";
        var OkayButtonName = (alertSingleData.alertData?.okayButtonName) ? alertSingleData.alertData?.okayButtonName : "";
        var ActionName = (alertSingleData.alertData?.actionName) ? alertSingleData.alertData?.actionName : "";
        return (
            alertSingleData.showAlert && (
                <AlertView
                    startImg={startImg}
                    title={Title}
                    subTitle={SubTitle}
                    singleOkButton={OkayButtonName}
                    handleOkay={() => handleAlertOkayButton(ActionName)}
                />
            )
        );
    };

    const handleClose = () => {
        setAlertDualData({
            showAlert: false,
        })
    };

    const ShowDualAlert = () => {
        var Title = (alertDualData.alertData?.title) ? alertDualData.alertData?.title : "";
        var SubTitle = (alertDualData.alertData?.subTitle) ? alertDualData.alertData?.subTitle : "";
        var DualAButton = (alertDualData.alertData?.dualAButton) ? alertDualData.alertData?.dualAButton : "";
        var DualBButton = (alertDualData.alertData?.dualBButton) ? alertDualData.alertData?.dualBButton : "";
        var startImg = (alertDualData.alertData?.startImg) ? alertDualData.alertData?.startImg : "";
        var ActionName = (alertDualData.alertData?.actionName) ? alertDualData.alertData?.actionName : "";
        var closeButton = alertDualData.alertData?.closeButton;
        return (
            alertDualData.showAlert && (
                <AlertView
                    title={Title}
                    subTitle={SubTitle}
                    startImg={startImg}
                    closeButton={closeButton}
                    dualAButton={DualAButton}
                    dualBButton={DualBButton}
                    handleDualAButton={() => handleDualAButton(ActionName)}
                    handleDualBButton={() => handleDualBButton(ActionName)}
                    handleClose={handleClose}
                />
            )
        );
    };

    const getIconForReport = (spotnumber: string) => {
        for (let i = 0; i < tempReportedArr.length; i++) {
            if (spotnumber === tempReportedArr[i].spot) {
                return tempReportedArr[i].offline ? cancelOrange : cancelRed;
            }
        }
        return cancelGray;
    };

    useEffect(() => {
        const onReportQueueSyncCompleted = () => {
            for (let i = 0; i < tempReportedArr.length; i++) {
                tempReportedArr[i].offline = false;
            }
            ReportQueue.iterate((request, key, index) => {
                if (request.endpoint === "report_parker" && request.body.propertyGuid === SessionDetails.propertyGuid) {
                    for (let i = 0; i < tempReportedArr.length; i++) {
                        if (request.body.spotName === tempReportedArr[i].spot) {
                            tempReportedArr[i].offline = true;
                            break;
                        }
                    }
                }
            });
            getListOfParker();
        };
        document.addEventListener("onReportQueueSyncCompleted", onReportQueueSyncCompleted);
        return () => document.removeEventListener("onReportQueueSyncCompleted", onReportQueueSyncCompleted);
    }, [SessionDetails.propertyGuid, tempReportedArr, getListOfParker]);

    const listItems = parkerList.map((lp, index) =>
        <div className="CenterView" key={`parker-${index}`}>
            <div style={{ 'width': '100%' }}>
                <div style={{ 'backgroundColor': index % 2 === 0 ? "#FFFFFF" : "#F8F8F8", 'borderTop': '1px solid #C2C6CA' }} className="RowViewSpaceBetween" >
                    <div style={{ 'width': '50%', 'justifyContent': 'center' }} className="CenterView"
                        onClick={() => {
                            onClickParker(lp);
                        }}>
                        <h1 style={{fontSize: 16}} className="truncate" >{`${lp["spotName"]}`}</h1>
                    </div>
                    <div style={{ 'width': '100%', 'justifyContent': 'center' }} className="CenterView"
                        onClick={() => {
                            onClickParker(lp);
                        }}>
                        <small className="SubTitle" style={{ fontSize: 16, 'textOverflow': 'ellipsis', 'overflow': 'hidden', 'display': 'inline-block' }}>
                            {(lp["licensePlate"] as string)?.split(",").map(x => <>{x}</>).reduce((all, x) => <>{all}<br/>{x}</>)}
                        </small>
                    </div>
                    <div style={{ 'width': '100%', 'justifyContent': 'center' }} className="CenterView"
                        onClick={() => {
                            onClickParker(lp);
                        }}>
                        <small className="SubTitle" style={{fontSize: 16, textAlign:'center'}}> {lp["endTimeLocal"] === "0001-01-01T00:00:00" ? "" : moment(lp["endTimeLocal"]).format("DD MMM YYYY hh:mm:A")}</small>
                    </div>
                    <div style={{ 'width': '50%', 'justifyContent': 'center' }} className="CenterView">
                        <img className="Arrow-Image" alt="" src={getIconForReport(lp["spotName"])}
                            onClick={() => {
                                getReasonList();
                                setSpotGuid(lp['spotGuid']);
                                setSpotName(lp['spotName']);
                            }}
                        ></img>
                    </div>
                </div>
            </div>
        </div>
    );

    return (
        <div className="adjust-screen" style={{
            height: "100vh",
            display: "grid",
            justifyItems: "center",
        }}>
            <div
                style={{
                    overflowY: 'auto',
                    overflow: 'auto',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                }}
            >
                {spinner && <SpinnerView />}
                {ShowokayAlert()}
                {ShowDualAlert()}
                <div className="BaseContainer">
                    {clearedLotPopUpView && (
                            <PopUpView
                                title={"Cleared  Lot"}
                                subTitle={`There have been ${tempReportedArr.length > 0 ? tempReportedArr.length :"no" } illegal ${tempReportedArr.length === 1 ? "parker" :"parkers" } reported. Please confirm that this is accurate.`}
                                dualAButton={"Go Back"}
                                dualBButton={"Complete Scan"}
                                handelDualAButton={() => {
                                    setClearedLotPopUpView(false);
                                    //setEnforcementCheckComplete(true);
                                    // navigate(-1)
                                    setAlertDualData({
                                        showAlert: true,
                                        alertData: {
                                            title: "Are you sure?",
                                            subTitle: "Do you want to go back?",
                                            startImg: require('../../Images/error-red.png'),
                                            dualAButton: "Yes",
                                            dualBButton: "No",
                                            actionName: "Go-Back"
                                        }
                                    });
                                }}
                                handelDualBButton={() => {
                                    setClearedLotPopUpView(false);
                                    setEnforcementCheckCompletePopUpView(true);
                                }}
                                handelClose={() => {
                                    setClearedLotPopUpView(false);
                                }}
                            ></PopUpView>
                        )
                    }
                    {
                        enforcementCheckCompletePopUpView && (
                            <PopUpView
                                startImg={require('../../Images/tick.png')}
                                title={"Complete Scan"}
                                //subTitle={["Parking enforcement check for", <b> {SessionDetails.propertyName} - {SessionDetails.selectedZone} </b>, " has been successfully completed. To continue start a new scan or end the session to log out."]}
                                subTitle={"Please confirm that you have completed scanning the entire zone"}
                                dualAButton={"End Session"}
                                dualBButton={"New Scan"}
                                handelDualAButton={() => {
                                    if (!listReachedBottom) {
                                        setAlertDualData({
                                            showAlert: true,
                                            alertData: {
                                                title: "Unfinished Scan",
                                                subTitle: "You’re not finished scanning! Do you want to quit anyway?",
                                                startImg: require('../../Images/error-red.png'),
                                                dualAButton: "Yes",
                                                dualBButton: "No",
                                                actionName: "Unfinished-Scan"
                                            }
                                        });
                                    } else {
                                        setAlertDualData({
                                            showAlert: true,
                                            alertData: {
                                                title: "Enforcement Check Complete",
                                                subTitle: `Parking enforcement check for ${SessionDetails.propertyName} - ${MultiSessionDetails.length > 0 ? MultiSessionDetails.map((x: any) => x["selectedZone"]).join(",") : ""} has been successfully completed. To proceed, please choose one of the following options:`,
                                                startImg: "",
                                                dualAButton: "End Session",
                                                dualBButton: "New Session",
                                                actionName: "End-Session",
                                                closeButton: CloseIcon
                                            }
                                        });
                                    }
                                }}
                                handelDualBButton={() => {
                                    setFlagToBack(true);
                                    navigate(-1);
                                }}
                                handelClose={() => {
                                    setEnforcementCheckCompletePopUpView(false);
                                }}
                            ></PopUpView>
                        )
                    }
                    {
                        reportParkerPopUpView && (
                            <ReportPopUpView
                                title={"Report Parker"}
                                spotGuid={spotGuid}
                                spotName={spotName}
                                reasonList={reasonList}
                                licensePlate={SearchText}
                                licensePlateEditable={isLicensePlateEditable.current}
                                subTitle={"Please enter the unauthorized vehicle’s information to complete the report."}
                                showReportMenu={true}
                                showSpotTF={isParkerNotInSystem.current}
                                handelClose={() => {
                                    setReportParkerPopUpView(false);
                                }}
                                handelOkButton={isParkerNotInSystem.current ? (licensePlate: any, makeTF: any, modelTF: any, colorTF: any, reasonId: any) => {
                                    getReportParker(licensePlate, makeTF, modelTF, colorTF, reasonId);
                                } : (licensePlate: any, makeTF: any, modelTF: any, colorTF: any) => {
                                    getReportParkerNotInSystem(licensePlate, makeTF, modelTF, colorTF);
                                }

                                }
                            ></ReportPopUpView>
                        )
                    } {
                        reportParkerSubmitPopUpView && (
                            <PopUpView
                                startImg={require('../../Images/tick.png')}
                                title={"Report Submitted"}
                                subTitle={[<span key="s1">This parker has been reported </span>, <b key="s2" style={{ color: reportedCount === 0 ? "#00AF85" : reportedCount < 6 ? "#F89C83" : "#DC2626", fontWeight: '900' }}>{reportedCount + " times"}</b>]}
                                subTitle2={[<b key="b1">Recommended Action:</b>, <br key="b2" />, <span key="b3">Leave GrydPark parking reminder on the windshield.</span>]}
                                dualBButton={"Continue Scanning"}
                                handelDualAButton={() => {
                                    if (!listReachedBottom) {
                                        setAlertDualData({
                                            showAlert: true,
                                            alertData: {
                                                title: "Unfinished Scan",
                                                subTitle: "You’re not finished scanning! Do you want to quit anyway?",
                                                startImg: require('../../Images/error-red.png'),
                                                dualAButton: "Yes",
                                                dualBButton: "No",
                                                actionName: "Unfinished-Scan"
                                            }
                                        });
                                    } else {
                                        getEndSession();
                                    }
                                }}
                                handelDualBButton={() => setReportParkerSubmitPopUpView(false)}
                                handelClose={() => setReportParkerSubmitPopUpView(false)}
                            ></PopUpView>
                        )
                    }
                    <div className="Sub-Container-View">
                        <div style={{ 'marginTop': '20px' }} className='RightView'>
                            <Button
                                onClick={() => {
                                    if (!listReachedBottom) {
                                        setAlertDualData({
                                            showAlert: true,
                                            alertData: {
                                                title: "Unfinished Scan",
                                                subTitle: "You’re not finished scanning! Do you want to quit anyway?",
                                                startImg: require('../../Images/error-red.png'),
                                                dualAButton: "Yes",
                                                dualBButton: "No",
                                                actionName: "Unfinished-Scan"
                                            }
                                        });
                                    } else {
                                        setAlertDualData({
                                            showAlert: true,
                                            alertData: {
                                                title: "Enforcement Check Complete",
                                                subTitle: `Parking enforcement check for ${SessionDetails.propertyName} - ${MultiSessionDetails.length > 0 ? MultiSessionDetails.map((x: any) => x["selectedZone"]).join(",") : ""} has been successfully completed. To proceed, please choose one of the following options:`,
                                                startImg: "",
                                                dualAButton: "End Session",
                                                dualBButton: "New Session",
                                                actionName: "End-Session",
                                                closeButton: CloseIcon
                                            }
                                        });
                                    }
                                }}
                            > End Session</Button>
                        </div>
                        <div className="CenterView">
                            <div>
                                <h2 className="title">
                                    {SessionDetails.propertyName} - {MultiSessionDetails.length > 0 ? MultiSessionDetails.map((x: any) => x["selectedZone"]).join(",") : ""}
                                </h2>
                            </div>
                            <div style={{ 'width': '100%' }}>
                                <TextField fullWidth className="MatrialTextFieldFull outlined-basic" size="medium" label="" variant="outlined" color="primary" autoComplete="off" value={SearchText}
                                    inputProps={{ maxLength: 20 }}
                                    inputRef={textInput}
                                    onChange={(e: any) => {
                                        if (e.target.value.length <= 20) {
                                            setSearchText(e.target.value);
                                        }
                                    }}
                                    onFocus={() => {
                                            const platform = window.navigator.platform
                                            if (!(platform.toLowerCase().includes('mac') || platform.toLowerCase().includes('iphone') || platform.toLowerCase().includes('ipad'))) {
                                                setDynamicHight('70vh');
                                            }
                                    }}
                                    onBlur={() => setDynamicHight('79vh')}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <img className="Arrow-Image" alt="" src={LocArrowsvg}></img>
                                            </InputAdornment>
                                        ),
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                {
                                                  SearchText && (
                                                    <img className="Arrow-Image" alt="" src={CloseSVG} onClick={() => setSearchText("")}></img>
                                                  )
                                                }
                                            </InputAdornment>
                                        )
                                    }}

                                />
                            </div>
                            {showParkerNotInSystem &&
                                (<div style={{ 'marginTop': '15vh', flexDirection: 'column', display: 'flex', alignItems: 'center' }}>
                                    <small style={{ margin: '2.5px' }} className="GrayHeader">Oh No! No Parkers Found</small>
                                    <small style={{ margin: '2.5px', padding: '12px', textAlign: 'center' }} className="GrayLabel">We can’t find any parkers matching your search</small>
                                    <small style={{ margin: '2.5px', 'textDecoration': 'underline' }} className="GreenLabel" onClick={() => {
                                        isParkerNotInSystem.current = false;
                                        isLicensePlateEditable.current = true;
                                        setSpotName("");
                                        setReportParkerPopUpView(true);
                                    }}>Report Parker</small>
                                </div>) || (<>

                                    <div style={{ 'width': '100%' }} className="RowViewSpaceBetween">
                                        <div style={{ 'width': '100%', 'backgroundColor': 'white' }} className="CenterView">
                                            <small className="SubTitle" style={{ 'textAlign': 'center', }}>SPOT #</small>
                                        </div>
                                        <div style={{ 'width': '100%', 'backgroundColor': 'white' }} className="CenterView">
                                            <small className="SubTitle" style={{ 'textAlign': 'center', }}>LICENSE PLATE</small>
                                        </div>
                                        <div style={{ 'width': '100%', 'backgroundColor': 'white' }} className="CenterView">
                                            <small className="SubTitle" style={{ 'textAlign': 'center' }}>END TIME</small>
                                        </div>
                                        <div style={{ 'width': '100%', 'backgroundColor': 'white' }} className="CenterView">
                                            <small className="SubTitle" style={{ 'textAlign': 'center' }}>REPORT</small>
                                        </div>
                                    </div>

                                    <div style={{ 'marginBottom': '80px', 'marginTop': '20px' }} className="ListOfParkers-min-hight" onScroll={() => onScroll()} ref={listInnerRef}>
                                        {
                                             isParkers &&(<div >{listItems}</div>) || (
                                             <div style={{'display':'flex','flexDirection':'column','justifyContent':'center','width':'100%',height:'100%',alignItems:'center'}}>
                                                <h1 className="GrayLabel">No spots in zone</h1>
                                             </div>)
                                        }

                                    </div>
                                </>)
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                position: 'fixed',
                bottom: '24px',
            }}>
                <Button
                    style={{
                        height: '34px',
                        backgroundColor: '#00AF85',
                        fontSize: "15px",
                        fontFamily: 'Manrope',
                        fontWeight: '700',
                        borderRadius: '18px',
                    }}
                    startIcon={<SearchIcon/>}
                    variant="contained"
                    onClick={() => {
                            //@ts-ignore
                            textInput.current?.focus();
                    }}
                    onMouseDown={() => {
                        //@ts-ignore
                        textInput.current?.focus();
                    }}
                >Search</Button>
            </div>
        </div>
    )
}

export default ListOfParkers;
